import React from 'react';
import CustomNavbar from '../components/CustomNavbar';
import growth from '../img/home2/growth.png';
import Budgeting from '../img/home2/Budgeting.png';
import BudgetingForecasting1 from '../img/home2/BudgetingForecasting1.png';
import ErpAction1 from '../components/ErpAction1';
import Reveal from 'react-reveal';
import FooterFour from '../components/Footer/FooterFour';
import { Helmet } from 'react-helmet';



const BudgetingForecasting =()=>{

    return(
        <div className="body_wrapper">
        <Helmet>
        <title>Financial Budget Accounting Services Agency – The Profise</title>
    <meta name="title" content="Financial Budget Accounting Services Agency – The Profise" />
    <meta name="description" content=" Budgeting in The Profise is preparing and managing a company's financial plan for a specified period, usually a year that outlines expected revenues, etc." />
        </Helmet>
        <CustomNavbar slogo="sticky_logo" mClass="menu_four" nClass="w_menu ml-auto mr-auto"/>

        <section className='BudgetingForecasting'>
          <div className='container'>
            <div className='row'>
            <div className='col-xxl-6 col-xl-6 col-lg-8 col-md-8'>
              <h2>Framing <span class="color-green1"> Your Budgets And Forecasts</span></h2>
            </div> 
            </div>
          </div>
        </section>
        <section className='innersection1'>
<div className='container'>
  <div className='row'>
    <div className='col-xxl-6 col-xl-6 col-md-6 col-12'>
    <h2> <span className="color-green-900">Budgeting</span> and Forecasting - Services We Offer  </h2>
    <p className='match'>We at The Profise help you frame your yearly budget and forecast for better future planning to grow your business. </p>
    <p><strong>Our key services for budgeting and forecasting are: </strong></p>
    <ul class="Passion">
    <li><span><i class="fa fa-angle-right" aria-hidden="true"></i></span>Variance analysis (actual vs. budget) </li>
    <li><span><i class="fa fa-angle-right" aria-hidden="true"></i></span>Assistance in preparing budgetary estimates </li>
    <li><span><i class="fa fa-angle-right" aria-hidden="true"></i></span>Analytical reports on variance  </li>
    <li><span><i class="fa fa-angle-right" aria-hidden="true"></i></span>Preparing forecast for future years</li>
    </ul>
    </div>
    <div className='col-xxl-6 col-xl-6 col-md-6 col-12'>
    <div className="frombackinner">
  <h3>Send Your Inquires</h3>
  <form
    action="fileUploadScript.php"
    method="post"
    encType="multipart/form-data"
  >
  <div className='row'>
    <div className='col-xxl-6 col-xl-6 col-md-12 col-12'>
    <label>Legal Company Name:*</label>
    <input type="text" name="name" className="form-control" required="" />
    </div>
    <div className='col-xxl-6 col-xl-6 col-md-12 col-12'>
    <label>Your Email:*</label>
    <input type="email" name="email" className="form-control" required="" />
    </div>
    <div className='col-xxl-6 col-xl-6 col-md-12 col-12'>
    <label>Your Number:*</label>
    <input type="tel" name="phone" className="form-control" required="" />
    </div>
    <div className='col-xxl-6 col-xl-6 col-md-12 col-12'>
    <label>Location</label>
    <input type="text" name="namentitye" className="form-control" required="" />
    </div>
    <div className='col-xxl-12 col-xl-12 col-md-12 col-12'>
    <label>Your Query</label>
    <textarea name="message" rows="4" class="form-control"> </textarea>
    </div>
       </div>
         <br></br>
    <input type="submit" className="form-rol" />
  </form>
</div>
    </div>
  </div>
</div>
        </section>
              <section className="purple">
   <div className="container">
    <div className="row">
      <div className="col-xl-6 col-lg-6 col-sm-6">
        <div className="basic">
<h2><span class="acct">Budgeting</span> and Forecasting - What Is It? </h2>
<div className="mke">
<p>Budgeting and forecasting often seem similar, but there is a difference between these two terms. Budgeting outlines planned expenses and revenue over a while of a business, while forecasting is a well-thought-out projection of a business's outcomes in the future. 
</p>
<p>Budgeting is prepared for the short term; on the other hand, forecasting is prepared for both the short and long time. Budgeting is more static as compared to forecasting. The financial forecast undergoes several changes depending on the business situation and economic conditions. </p>
</div>
        </div>
      </div>
      <div className="col-xl-6 col-lg-6 col-sm-6">
        <div className="basic2">
        <img src={growth} />
        </div>
      </div>
    </div>
   </div>

   </section>

      <section className='abouttwo'>
            <div className='container'>
                <div className='row' id='Profitable'>
                <div className='col-xxl-6 col-xl-6 col-md-6 col-12 girl text-center'>
                <img src={Budgeting} />
                 </div>
                <div className='col-xxl-6 col-xl-6 col-md-6 col-12'>
                 <h2> <span className="color-green-900">Benefits </span>Of Budgeting And Forecasting</h2>
                 <p className='Profitable'>Every business approaches budgeting and forecasting differently, depending on the scope and complexity of the processes determined by the company’s structure, size, and industry. </p>
                 <br></br>
                 <p>Many businesses use their financial information to improve operating results to run their business efficiently. The benefits of budgeting and forecasting are: </p>
                 <br></br>
            
                </div>

                </div>
                <div className='row' id='tripple'>
                <div className="col-lg-4">
                        <div className="media tracking_item">
                            <img src={require ("../img/home-tracking/next.png")} alt=""/>
                            <div className="media-body">
                                <h3 className="h_head">It Helps in understanding what the numbers mean and what decisions you should make.</h3>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="media tracking_item">
                            <img src={require ("../img/home-tracking/next.png")} alt=""/>
                            <div className="media-body">
                                <h3 className="h_head">It Helps set the company’s goals and monitor the progress to make the appropriate changes. </h3>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="media tracking_item">
                            <img src={require ("../img/home-tracking/next.png")} alt=""/>
                            <div className="media-body">
                               <h3 className="h_head">It Helps forecast future results and make the essential changes to achieve the desired goals.</h3>
                            </div>
                        </div>
                </div>
                </div>
                     </div>
           </section>
           <section className="get_started_area">
            <div className="container">
                <div className="row pb-20">
                    <div className="col-xl-5 col-lg-6 col-md-6 d-flex align-items-center">
                        <Reveal bottom cascade>
                            <div className="get_content">
                             <h2 className="l_height50 mb_20 wow fadeInLeft" data-wow-delay="0.3s"><span className='acct'>The </span>  Budgeting and Forecasting Process   </h2>                              
                               </div>
                        </Reveal>
                    </div>
                    <div className="col-xl-7 col-lg-6 col-md-6 text-right">
                       <Reveal effect="fadeInRight" duration={2200}> 
                       <p className="righttext f_400 f_p mb_40 wow fadeInLeft" data-wow-delay="0.4s">Budgeting and forecasting help determine and map out an organization's short and long-term financial goals. <strong>The budgeting and forecasting process includes the following: </strong></p>
                       </Reveal>
                    </div>
                </div>

                <div className="row">
                    <div className="col-xl-3 col-lg-3 col-md-6 d-flex align-items-center">
                        <Reveal bottom cascade>
                            <div className="get_content">
                            <Reveal effect="fadeInRight" duration={2200}> <img src={require('../img/home2/financial.png')} alt="" className='servimg'/></Reveal>
                            <h2 className="acc l_height50 mb_20 wow fadeInLeft" data-wow-delay="0.3s">Frame a realistic budget & perform scenario planning</h2>                                 
                            </div>
                        </Reveal>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-6 d-flex align-items-center">
                        <Reveal bottom cascade>
                            <div className="get_content">
                            <Reveal effect="fadeInRight" duration={2200}> <img src={require('../img/home2/data.png')} alt="" className='servimg'/></Reveal>
                            <h2 className="acc l_height50 mb_20 wow fadeInLeft" data-wow-delay="0.3s">Start collecting the clean data to create a budget & forecast</h2>                                   
                            </div>
                        </Reveal>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-6 d-flex align-items-center">
                        <Reveal bottom cascade>
                            <div className="get_content">
                            <Reveal effect="fadeInRight" duration={2200}> <img src={require('../img/home2/budget-plan.png')} alt="" className='servimg'/></Reveal>
                            <h2 className="acc l_height50 mb_20 wow fadeInLeft" data-wow-delay="0.3s">Plan short or long-term budgets and forecasts </h2>                                          
                            </div>
                        </Reveal>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-6 d-flex align-items-center">
                        <Reveal bottom cascade>
                            <div className="get_content">
                            <Reveal effect="fadeInRight" duration={2200}> <img src={require('../img/home2/monitor-budget.png')} alt="" className='servimg'/></Reveal>
                            <h2 className="acc l_height50 mb_20 wow fadeInLeft" data-wow-delay="0.3s">Constantly monitor the budget & update the forecasts. </h2>                                    
                            </div>
                        </Reveal>
                    </div>
                </div>

            

            </div>
        </section>
 
      <section className="why-learn-area pb-120 rpb-100">
        <div className="container">
          <div className="row align-items-center large-gap wow fadeInLeft delay-0-2s">
            <div className="col-xl-6 col-lg-6 col-md-6">
              <div className="why-learn-content rmb-35">
                <div className="section-title mb-30">
                   <h2><span className="color-green-900">Why Are </span> Our Budgeting and Forecasting Services Different? </h2>
                  <p className="Profitable">A budget sets a path ahead and provides us with boundaries to operate, while forecasting provides us with a better plan for future paths. </p>
                </div>
                <p>
                Our experts collect all your data and help you and your team to frame budget estimates for the upcoming year for proper planning and management of your business. We help you with variance analysis for the actual performance with a budget. 
                </p>
                <p>
                We help you identify the pain areas and underperforming sections of your business by taking a corrective approach to handling them.  </p>
               
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 text-center">
              <div className="why-learn-image wow fadeInUp" data-wow-delay="0.4s">
               <img src={BudgetingForecasting1} className="bluegirl" />
            
              </div>
            </div>
          </div>
        </div>
      </section>
    
     
    
            <ErpAction1/>
       <FooterFour />
                  </div>
    )
}
export default BudgetingForecasting;