import React from 'react';
import CustomNavbar from '../components/CustomNavbar';
import others1 from '../img/home2/others1.png';
import others2 from '../img/home2/others2.png';
import {Link} from 'react-router-dom';
import featurefiveicon1 from '../img/home2/accounting1.png';
import featurefiveicon2 from '../img/home2/network1.png';
import featurefiveicon3 from '../img/home2/checklist1.png';
import featurefiveicon4 from '../img/home2/social-media1.png';
import ErpAction1 from '../components/ErpAction1';
import FooterFour from '../components/Footer/FooterFour';
import { Helmet } from 'react-helmet';


const Others = () => {
    return(
        <div className="body_wrapper">
        <Helmet>
        <title>Best Accounting Services For Others – The Profise</title>
    <meta name="title" content="Best Accounting Services For Others – The Profise" />
    <meta name="description" content="The Profise can provide valuable support to industries such as retail, construction, and many others, helping them manage their finances efficiently." />
        </Helmet>
            <CustomNavbar slogo="sticky_logo" mClass="menu_four" nClass="w_menu ml-auto mr-auto"/>
            <section className='others'>
        <div className='container'>
            <div className='row'>
            <div className='col-md-8 col-xxl-6 col-xl-6'>
              <h2>Advanced <span class="color-green1"> business solutions for everyone</span></h2>
                </div>   
                
            </div>
        </div>
            </section>
            <section className='abouttwo'>
            <div className='container'>
                <div className='row'>
                <div className='col-xxl-6 col-xl-6 col-md-6 col-12'>
                <img src={others1} className="aboutfirst" />
                
                </div>
                <div className='col-xxl-6 col-xl-6 col-md-6 col-12'>
                 <h2> <span className="color-green-900">Improve </span> the way you work with The Profise</h2>
                 <p className='Profitable'>We provide 360-degree business solutions for all kinds of industries. </p>
                 <br></br>
                 <p>Our experts in various fields with multiple specialities will help your business from handling your finances to giving you a brand new website. You can happily focus on growth. </p>
                 <Link to="/About-us" className="z-btn z-btn-border">ABOUT US</Link>
                </div>
                </div>
            </div>
            </section> 
            <section className="purple">
   <div className="container">
    <div className="row">
      <div className="col-lg-6 col-xxl-6 col-md-12 col-xxl-6 col-12">
        <div className="basic">
<h2><span className="color-green-900">More </span> flexible working  </h2>
<div className="mke">
<p>We at The Profise handle all the major operations that your business requires. It contains taking care of your finances, data entry, staffing and digital marketing. </p>
<p>Your business will grow digitally and the process will be error-free. We have already helped industries like healthcare, trucking, retail and manufacturing. </p>
</div>
        </div>
      </div>
      <div className="col-lg-6 col-xxl-6 col-xxl-6 col-12">
        <div className="basic2">
        <img src={others2} />
        </div>
      </div>
    </div>
   </div>

            </section>  
            <section className="freature-section-six pt-120 rpt-90 pb-90 rpb-60">
        <div className="container">
          <div className="row large-gap mb-30">
            <div className="col-lg-6">
              <div className="freature-six-left wow fadeInUp delay-0-2s">
                <div className="section-title mb-30">
                  <h2> <span className="color-green-900">Get ahead </span> & chase your dreams </h2>       
                </div>
              </div>
            </div>
            <div className="col-lg-5">
              <div className="freature-six-right pt-55 rpt-0 wow fadeInUp delay-0-4s">
                <p>
                Listed here are some of the major services that we provide to help you achieve the most through your business. 
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-3 col-sm-6">
              <div className="feature-six-item mt-30 wow fadeInUp delay-0-2s">
                <div className="content">
                  <div className="icon">
                  <img src={featurefiveicon1} />
                   
                  </div>
                  <h5 className='payable'>Accounting</h5>
                                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="feature-six-item wow fadeInUp delay-0-4s">
                <div className="content">
                  <div className="icon">
                  <img src={featurefiveicon2} />
                  </div>
                  <h5 className='payable'>Staffing</h5>
                                 </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="feature-six-item mt-30 wow fadeInUp delay-0-6s">
                <div className="content">
                  <div className="icon">
                  <img src={featurefiveicon3} />
                  </div>
                  <h5 className='payable'>Data Entry</h5>
                                 </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="feature-six-item wow fadeInUp delay-0-8s">
                <div className="content">
                  <div className="icon">
                  <img src={featurefiveicon4} />
                  </div>
                  <h5 className='payable'>Digital Marketing </h5>
                                 </div>
              </div>
            </div>
          </div>
        </div>
            </section>
           <ErpAction1/>
           <FooterFour />
           </div>
    )
}
export default Others;