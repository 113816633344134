import React, {Component} from 'react';
import {Link, NavLink} from 'react-router-dom';
import Sticky from 'react-stickynode';

class CustomNavbar extends Component {
    render() {
        var {mClass, nClass, cClass, slogo, hbtnClass} =this.props;
        return (
            <Sticky top={0} innerZ={9999} activeClass="navbar_fixed">
                <header className="header_area">
                <nav className={`navbar navbar-expand-lg menu_one ${mClass}`}>
                    <div className={`container ${cClass}`}>
                        <Link className={`navbar-brand ${slogo}`} to="/">
                            <img src={require("../img/logo.png")} alt=""/>
                            <img src={require("../img/logo2.png")} alt="logo"/>
                        </Link>
                        <button className="navbar-toggler collapsed" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="menu_toggle">
                                <span className="hamburger">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </span>
                                <span className="hamburger-cross">
                                    <span></span>
                                    <span></span>
                                </span>
                            </span>
                        </button>

                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul className={`navbar-nav menu ml-auto ${nClass}`}>
            
                                <li className="nav-item"><NavLink title="Home" className="nav-link" to="./">Home</NavLink></li>
                                <li className="nav-item"><NavLink title="About-us" className="nav-link" to="/About-us">About Us</NavLink></li>
                                <li className="nav-item"><NavLink title="About-us" className="nav-link" to="/Whychooseus">Why Choose Us</NavLink></li>

                                <li className="dropdown submenu nav-item">
                                    <Link to="./" title="Pages" className="dropdown-toggle nav-link" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">Service</Link>
                                    <ul role="menu" className=" dropdown-menu">
                                        <li className="nav-item"><NavLink exact title="Service Details" className="nav-link" to='./'>Accounting</NavLink>
                                        <li className="dropdown submenu nav-item">

                                    <ul role="menu" className=" dropdown-menu">
                                        <li className="nav-item"><NavLink exact title="Service Details" className="nav-link" to='/Accountspayable'>Accounts Payable</NavLink></li>
                                        <li className="nav-item"><NavLink exact title="Service Details" className="nav-link" to='/AccountsReceivable'>Accounts Receivable</NavLink></li>
                                        <li className="nav-item"><NavLink exact title="Service Details" className="nav-link" to='/Reconciliation'>Reconciliation</NavLink></li>
                                        <li className="nav-item"><NavLink exact title="Service Details" className="nav-link" to='/BudgetingForecasting'>Budgeting & Forecasting</NavLink></li>
                                        <li className="nav-item"><NavLink exact title="Service Details" className="nav-link" to='/MISFinancialStatementsPreparation'>MIS/Financial Statements Preparation</NavLink></li>
                                        <li className="nav-item"><NavLink exact title="Service Details" className="nav-link" to='/Payroll'>Payroll</NavLink></li>
                                        <li className="nav-item"><NavLink exact title="Service Details" className="nav-link" to='/TaxComplianceandFilings'>Tax Compliance & Filings</NavLink></li>

                                        <li className="nav-item"><NavLink exact title="Service Details" className="nav-link" to='/Bookkeeping'>Bookkeeping</NavLink></li>
                                        <li className="nav-item"><NavLink exact title="Service Details" className="nav-link" to='/Invoicingandbilling'>Invoicing & Billing</NavLink></li>

                                    </ul>
                                        </li>
                                        </li>

                                        <li className="nav-item"><NavLink exact title="Service Details" className="nav-link" to='/Staffing'>Staffing</NavLink></li>
                                        <li className="nav-item"><NavLink exact title="Service Details" className="nav-link" to='/DataEntry'>Data Entry</NavLink></li>
                                        <li className="nav-item"><NavLink exact title="Service Details" className="nav-link" to='/WebsiteDevelopment'>Website Development</NavLink></li>
                                        <li className="nav-item"><NavLink exact title="Service Details" className="nav-link" to='/DigitalMarketing'>Digital Marketing</NavLink></li>

                                    </ul>
                                </li>

                                <li className="dropdown submenu nav-item">
                                    <Link to="./" title="Pages" className="dropdown-toggle nav-link" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">Industries</Link>
                                    <ul role="menu" className=" dropdown-menu">
                                        <li className="nav-item"><NavLink exact title="Trucking" className="nav-link" to='/Trucking'>Trucking</NavLink></li>
                                        <li className="nav-item"><NavLink exact title="Trucking" className="nav-link" to='/Healthcare'>Healthcare</NavLink></li>
                                        <li className="nav-item"><NavLink exact title="Trucking" className="nav-link" to='/Manufacturing'>Manufacturing</NavLink></li>
                                        <li className="nav-item"><NavLink exact title="Trucking" className="nav-link" to='/Others'>Others</NavLink></li>
                                    </ul>
                                </li>
                                <li className="nav-item"><NavLink title="On-boarding" className="nav-link" to="/On-boarding">On-Boarding</NavLink></li>
                                <li className="nav-item"><NavLink title="Blog" className="nav-link" to="/">Blog</NavLink></li>                             
                                <li className="nav-item"><NavLink title="Pricing" className="nav-link" to="/Contact">Contact</NavLink></li>
                            </ul>
                            {/*
                            <a className={`btn_get btn_hover ${hbtnClass}`} href="#get-app">Get Started</a>*/}
                        </div>
                    </div>
                </nav>
                </header>
            </Sticky>
        );
    }
}

export default CustomNavbar;