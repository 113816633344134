import React from 'react';
import CustomNavbar from '../components/CustomNavbar';
import growth from '../img/home2/growth.png';
import benefits from '../img/home2/benefits.png';
import benefits2 from '../img/home2/benefits2.png';
import ErpAction1 from '../components/ErpAction1';
import Reveal from 'react-reveal';
import FooterFour from '../components/Footer/FooterFour';
import { Helmet } from 'react-helmet';




const MISFinancialStatementsPreparation =()=>{
    
    return(
        <div className="body_wrapper">
        <Helmet>
        <title>MIS Financial Statement Preparation Services Company</title>
    <meta name="title" content="MIS Financial Statement Preparation Services Company" />
    <meta name="description" content="MIS and financial statement preparation in The Profise is a critical aspect that involves gathering, analyzing, and presenting financial information." />
        </Helmet>
        <CustomNavbar slogo="sticky_logo" mClass="menu_four" nClass="w_menu ml-auto mr-auto"/>

        <section className='financebanner'>
          <div className='container'>
            <div className='row'>
            <div className='col-xxl-8 col-xl-8 col-lg-8 col-md-8'>
              <h2>Reliable MIS/Financial<span class="color-green1"> Statements Preparation</span></h2>
            </div> 
            </div>
          </div>
        </section>
        <section className='innersection1'>
<div className='container'>
  <div className='row'>
    <div className='col-xxl-6 col-xl-6 col-md-6 col-12'>
    <h2> <span className="color-green-900">MIS/Financial</span> Statements Preparation - Services We Offer  </h2>
    <p className='match'>We at The Profise provide you with detailed monthly and year-ending accounting reports as it is required to analyze the cash flow and profitability of the business.  </p>
    <p><strong>Our key services for MIS/ financial statement preparation are: </strong></p>
    <ul class="Passion">
    <li><span><i class="fa fa-angle-right" aria-hidden="true"></i></span>Year-end closing of books with the journal adjustments.</li>
    <li><span><i class="fa fa-angle-right" aria-hidden="true"></i></span>Preparation of the financial statements from the books.  </li>
    <li><span><i class="fa fa-angle-right" aria-hidden="true"></i></span>General ledger adjustments and checking.   </li>
    <li><span><i class="fa fa-angle-right" aria-hidden="true"></i></span>Reconciliation of liabilities & assets at the end of the year. </li>
    </ul>
    </div>
    <div className='col-xxl-6 col-xl-6 col-md-6 col-12'>
    <div className="frombackinner">
  <h3>Send Your Inquires</h3>
  <form
    action="fileUploadScript.php"
    method="post"
    encType="multipart/form-data"
  >
  <div className='row'>
    <div className='col-xxl-6 col-xl-6 col-md-12 col-12'>
    <label>Legal Company Name:*</label>
    <input type="text" name="name" className="form-control" required="" />
    </div>
    <div className='col-xxl-6 col-xl-6 col-md-12 col-12'>
    <label>Your Email:*</label>
    <input type="email" name="email" className="form-control" required="" />
    </div>
    <div className='col-xxl-6 col-xl-6 col-md-12 col-12'>
    <label>Your Number:*</label>
    <input type="tel" name="phone" className="form-control" required="" />
    </div>
    <div className='col-xxl-6 col-xl-6 col-md-12 col-12'>
    <label>Location</label>
    <input type="text" name="namentitye" className="form-control" required="" />
    </div>
    <div className='col-xxl-12 col-xl-12 col-md-12 col-12'>
    <label>Your Query</label>
    <textarea name="message" rows="4" class="form-control"> </textarea>
    </div>
       </div>
         <br></br>
    <input type="submit" className="form-rol" />
  </form>
</div>
    </div>
  </div>
</div>
        </section>
        <section className="purple">
   <div className="container">
    <div className="row">
      <div className="col-xl-6 col-lg-6 col-sm-6">
        <div className="basic">
<h2><span class="acct">MIS/Financial</span> Statements Preparation - What Is It?</h2>
<div className="mke">
<p>MIS/financial statements preparation is the process of creating financial statements from a company's accounting records. MIS is an organized system that helps to store, collect and disseminate data in the form of certain information to carry out management functions. It is an essential tool that helps in supporting the decision-making process. </p>
<p>Large organizations need to report daily for some activities like inventory and sales. Our team helps you to integrate all the data from different departments at multiple locations and makes your task easier. 
</p>
</div>
        </div>
      </div>
      <div className="col-xl-6 col-lg-6 col-sm-6">
        <div className="basic2">
        <img src={growth} alt="" />
        </div>
      </div>
    </div>
   </div>

   </section>
      <section className='abouttwo'>
            <div className='container'>
                <div className='row' id='Profitable'>
                <div className='col-xxl-6 col-xl-6 col-md-6 col-12 girl text-center'>
                <img src={benefits} alt="" />
                 </div>
                <div className='col-xxl-6 col-xl-6 col-md-6 col-12'>
                 <h2> <span className="color-green-900">Benefits </span>Of MIS/Financial Statements Preparation</h2>
                 <p className='Profitable'>Every business needs performance indicator data periodically so to make informative decisions accordingly. </p>
                 <br></br>
                 <p>Managing a good MIS can be done by tracking reports daily, weekly, monthly, and annually to predict the business’ performance. The benefits of MIS reports are:</p>
                 <br></br>
            
                </div>

                </div>
                <div className='row' id='tripple'>
                <div className="col-lg-4">
                        <div className="media tracking_item">
                            <img src={require ("../img/home-tracking/next.png")} alt=""/>
                            <div className="media-body">
                                <h3 className="h_head">It helps in managing data effectively, so it is retrieved easily.</h3>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="media tracking_item">
                            <img src={require ("../img/home-tracking/next.png")} alt=""/>
                            <div className="media-body">
                                <h3 className="h_head">It can store historical data, which makes analyzing data accessible. </h3>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="media tracking_item">
                            <img src={require ("../img/home-tracking/next.png")} alt=""/>
                            <div className="media-body">
                               <h3 className="h_head">It helps in building long-term strategies for the future. </h3>
                            </div>
                        </div>
                </div>
                </div>
                     </div>
           </section>
           <section className="get_started_area">
            <div className="container">
                <div className="row pb-20">
                    <div className="col-xl-5 col-lg-6 col-md-6 d-flex align-items-center">
                        <Reveal bottom cascade>
                            <div className="get_content">
                             <h2 className="l_height50 mb_20 wow fadeInLeft" data-wow-delay="0.3s"><span className='acct'>The MIS/Financial </span> Statements Preparation Process </h2>                              
                               </div>
                        </Reveal>
                    </div>
                    <div className="col-xl-7 col-lg-6 col-md-6 text-right">
                       <Reveal effect="fadeInRight" duration={2200}> 
                       <p className="righttext f_400 f_p mb_40 wow fadeInLeft" data-wow-delay="0.4s">The MIS reports can be prepared manually or using software, and for both ways, access to accurate and complete data is the essential requirement. We design your MIS reports using the latest technology, making the process easier.  
                  <strong> Preparing a financial report process includes the following: </strong></p>
                       </Reveal>
                    </div>
                </div>

                <div className="row">
                    <div className="col-xl-3 col-lg-3 col-md-6 d-flex align-items-center">
                        <Reveal bottom cascade>
                            <div className="get_content">
                            <Reveal effect="fadeInRight" duration={2200}> <img src={require('../img/home2/financial-statements.png')} alt="" className='servimg'/></Reveal>
                            <h2 className="acc l_height50 mb_20 wow fadeInLeft" data-wow-delay="0.3s">Confirm the purpose of the financial statements.</h2>                                 
                            </div>
                        </Reveal>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-6 d-flex align-items-center">
                        <Reveal bottom cascade>
                            <div className="get_content">
                            <Reveal effect="fadeInRight" duration={2200}> <img src={require('../img/home2/data-collection.png')} alt="" className='servimg'/></Reveal>
                            <h2 className="acc l_height50 mb_20 wow fadeInLeft" data-wow-delay="0.3s">Collect the required financial data.</h2>                                   
                            </div>
                        </Reveal>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-6 d-flex align-items-center">
                        <Reveal bottom cascade>
                            <div className="get_content">
                            <Reveal effect="fadeInRight" duration={2200}> <img src={require('../img/home2/business-plan.png')} alt="" className='servimg'/></Reveal>
                            <h2 className="acc l_height50 mb_20 wow fadeInLeft" data-wow-delay="0.3s">Prepare the financial statements. </h2>                                          
                            </div>
                        </Reveal>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-6 d-flex align-items-center">
                        <Reveal bottom cascade>
                            <div className="get_content">
                            <Reveal effect="fadeInRight" duration={2200}> <img src={require('../img/home2/monitor-budget.png')} alt="" className='servimg'/></Reveal>
                            <h2 className="acc l_height50 mb_20 wow fadeInLeft" data-wow-delay="0.3s">Constantly monitor the budget & update the forecasts. </h2>                                    
                            </div>
                        </Reveal>
                    </div>
                </div>

            

            </div>
        </section>
       <section className="why-learn-area pb-120 rpb-100">
        <div className="container">
          <div className="row align-items-center large-gap wow fadeInLeft delay-0-2s">
            <div className="col-xl-6 col-lg-6 col-md-6">
              <div className="why-learn-content rmb-35">
                <div className="section-title mb-30">
                   <h2><span className="color-green-900">What</span> Makes Us Different From Others?  </h2>
                  <p className="Profitable">We at The Profise provide you with a bird’s eye view of all the operations and lead to actions on time. </p>
                </div>
                <p>
                We have the most skilled and experienced financial experts who help you with your financial requirements. We always focus on providing high-quality services that enhance the client’s decision-making process. We maintain a high level of confidentiality and data security for all the information and data of our clients. 
                </p>
                <p>
                We do our work using the best and latest financial tools available. Our experts know powerful and modern workstations and use encrypted technology to ensure that your data is safe and secure.
                </p>
               
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 text-center">
              <div className="why-learn-image wow fadeInUp" data-wow-delay="0.4s">
               <img src={benefits2} className="bluegirl" alt="" />
            
              </div>
            </div>
          </div>
        </div>
      </section>
      <ErpAction1/>
          <FooterFour />
                  </div>
    )
}
export default MISFinancialStatementsPreparation;