import React from 'react';
import CustomNavbar from '../components/CustomNavbar';
import FaqSection from '../components/FaqSection';
import FooterFour from '../components/Footer/FooterFour';

const Faq = () => {
    return(
        <div className="body_wrapper">
            <CustomNavbar slogo="sticky_logo" mClass="menu_four" nClass="w_menu ml-auto mr-auto"/>
            <section className='faq'>
        <div className='container'>
            <div className='row'>
            <div className='col-xxl-6'>
              <h2> Frequently <span className="color-green-900"> asked questions</span></h2>
               </div>   
                
            </div>
        </div>
           </section>
            <FaqSection/>
           <FooterFour />
        </div>
    )
}
export default Faq;