import React from 'react';
import CustomNavbar from '../components/CustomNavbar';
import Manufacturing1 from '../img/home2/Manufacturing1.png';
import Manufacturing2 from '../img/home2/Manufacturing2.png';
import {Link} from 'react-router-dom';
import featurefiveicon1 from '../img/home2/accounting1.png';
import featurefiveicon2 from '../img/home2/network1.png';
import featurefiveicon3 from '../img/home2/checklist1.png';
import featurefiveicon4 from '../img/home2/social-media1.png';
import ErpAction1 from '../components/ErpAction1';
import FooterFour from '../components/Footer/FooterFour';
import { Helmet } from 'react-helmet';


const Manufacturing = () => {
    return(
        <div className="body_wrapper">
        <Helmet>
        <title>Best Accounting Services For Manufacturing – The Profise</title>
    <meta name="title" content="Best Accounting Services For Manufacturing – The Profise" />
    <meta name="description" content="The Profise can help manufacturing industries manage their finances effectively, comply with regulations, and make informed decisions." />
        </Helmet>
            <CustomNavbar slogo="sticky_logo" mClass="menu_four" nClass="w_menu ml-auto mr-auto"/>
            <section className='manufacturebanner'>
        <div className='container'>
            <div className='row'>
            <div className='col-md-8 col-xxl-6 col-xl-6'>
              <h2>Streamlined <span class="color-green1">manufacturing with The Profise</span></h2>
                </div>   
                
            </div>
        </div>
            </section>
            <section className='abouttwo'>
            <div className='container'>
                <div className='row'>
                <div className='col-xxl-6 col-xl-6 col-md-6 col-12'>
                <img src={Manufacturing2} className="aboutfirst" />
                
                </div>
                <div className='col-xxl-6 col-xl-6 col-md-6 col-12'>
                 <h2> <span className="color-green-900">Maximise revenue, </span> Minimise expenses </h2>
                 <p className='Profitable'>The workload gets a lot easier when the process is automated. </p>
                 <br></br>
                 <p>We integrate multiple functions of the business into a single system, including production planning, inventory management, and financials. </p>
                 <Link to="/About-us" className="z-btn z-btn-border">ABOUT US</Link>
                </div>
                </div>
            </div>
            </section> 
            <section className="purple">
   <div className="container">
    <div className="row">
      <div className="col-lg-6 col-xxl-6 col-md-12 col-xxl-6 col-12">
        <div className="basic">
<h2><span className="color-green-900">Maintain </span> supply chain management </h2>
<div className="mke">
<p>Our business solutions will optimize the flow of materials and data from suppliers to manufacturers to customers. It boosts efficiency by lowering lead times, inventory levels, and costs. </p>
<p>In addition to this, you can track and improve the quality of your products through multilevel checking. We use advanced technologies to make it all easier for you. </p>
</div>
        </div>
      </div>
      <div className="col-lg-6 col-xxl-6 col-xxl-6 col-12">
        <div className="basic2">
        <img src={Manufacturing1} />
        </div>
      </div>
    </div>
   </div>

            </section>  
            <section className="freature-section-six pt-120 rpt-90 pb-90 rpb-60">
        <div className="container">
          <div className="row large-gap mb-30">
            <div className="col-lg-6">
              <div className="freature-six-left wow fadeInUp delay-0-2s">
                <div className="section-title mb-30">
                  <h2> <span className="color-green-900">Innovate, </span> Initiate, Improve </h2>       
                </div>
              </div>
            </div>
            <div className="col-lg-5">
              <div className="freature-six-right pt-55 rpt-0 wow fadeInUp delay-0-4s">
                <p>
                We are here to improve efficiency and decision-making by providing real-time data and analytics.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-3 col-sm-6">
              <div className="feature-six-item mt-30 wow fadeInUp delay-0-2s">
                <div className="content">
                  <div className="icon">
                  <img src={featurefiveicon1} />
                   
                  </div>
                  <h5 className='payable'>Accounting</h5>
                                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="feature-six-item wow fadeInUp delay-0-4s">
                <div className="content">
                  <div className="icon">
                  <img src={featurefiveicon2} />
                  </div>
                  <h5 className='payable'>Staffing</h5>
                                 </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="feature-six-item mt-30 wow fadeInUp delay-0-6s">
                <div className="content">
                  <div className="icon">
                  <img src={featurefiveicon3} />
                  </div>
                  <h5 className='payable'>Data Entry</h5>
                                 </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="feature-six-item wow fadeInUp delay-0-8s">
                <div className="content">
                  <div className="icon">
                  <img src={featurefiveicon4} />
                  </div>
                  <h5 className='payable'>Digital Marketing </h5>
                                 </div>
              </div>
            </div>
          </div>
        </div>
            </section>
           <ErpAction1/>
           <FooterFour />
           </div>
    )
}
export default Manufacturing;