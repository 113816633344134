import React from 'react';
import CustomNavbar from '../components/CustomNavbar';
import Contacts from '../components/Contacts';
import FooterFour from '../components/Footer/FooterFour';
import { Helmet } from 'react-helmet';

const About = () => {
    return(
        <div className="body_wrapper">
        <Helmet>
        <title>Get in Touch With Us For Accounting Services – The Profise</title>
    <meta name="title" content="Get in Touch With Us For Accounting Services – The Profise" />
    <meta name="description" content="Build a more scalable business with The Profise; let us handle all your finances. We will reduce your business taxes, save more, and make informed decisions." />
        </Helmet>
            <CustomNavbar slogo="sticky_logo" mClass="menu_four" nClass="w_menu ml-auto mr-auto"/>
            <section className='contact'>
        <div className='container'>
            <div className='row'>
            <div className='col-md-8 col-xxl-6 col-xl-8'>
              <h2>Contact  <span class="color-green1">Us</span></h2>
                </div>   
                
            </div>
        </div>
           </section>
                      <Contacts/>
         <FooterFour />
        </div>
    )
}
export default About;