import React from 'react';
import CustomNavbar from '../components/CustomNavbar';
import ErpAction1 from '../components/ErpAction1';
import FooterFour from '../components/Footer/FooterFour';



const Terms =()=>{
    

    return(
        <div className="body_wrapper">
        <CustomNavbar slogo="sticky_logo" mClass="menu_four" nClass="w_menu ml-auto mr-auto"/>

        <section className='aboutbanner1'>
          <div className='container'>
            <div className='row'>
            <div className='col-xxl-6 col-xl-6 col-lg-5 col-md-5'>
              <h2>Terms <span class="color-green1"> of use</span></h2>
            </div> 
            </div>
          </div>
        </section>
        <section className="why-learn-area pb-120 rpb-100">
        <div className="container">
          <div className="row align-items-center large-gap wow fadeInLeft delay-0-2s">

            <div className="col-xl-12 col-lg-12 col-md-12">
              <div className="why-learn-content rmb-35">
                <h4 className='priv'><span>Overview</span></h4>
                <p>Welcome to The Profise Accounting Services. Thank you for visiting our website. Following are our terms of use; please have a look. The following terms stipulate a legal agreement between The Profise Pvt. Ltd. and you. By accessing, using, or browsing this website, you accept that you have read, understood, and agreed to these terms. Also, you agree to conform to all the appropriate regulations and laws. Please use this website only if you agree to our terms of use. These terms of use are subject to modifications and changes. The Profise may amend the following terms of use and any other information on this website at any point without prior notice. The changed or updated terms of use and other information will be posted on this website. 
              </p>
            

                <h4 className='priv'><span>General  </span></h4>
                <p>The copyright stringently protects this website. The copyright terms and conditions must be strictly followed. This website, the information, and all the content on this website are restricted from being reproduced, republished, reposted, transmitted, distributed, uploaded, copied, or used for any of the plagiaristic work without the prior and written consent of The Profise unless The Profise allows you non-exclusive, non-transferable and limited permission to exhibit the pages on this website, on your computer for your non-commercial or personal use of the information. None of the content on this website may be mirrored to another website or any other media. However, you cannot modify any content and must abide by the copyright, propriety notices, trademark, and conditions mentioned on this website. </p>


                <h4 className='priv'><span>Disclaimer </span></h4>
                <p>The Profise may contain clerical errors, typographical errors, and inaccuracies. The Profice Accounting Services disclaims explicitly any compulsion to update this website or any of the information on this website. The Profise Accounting Services alone reserves the right, in its sole discretion, to correct errors or omissions in this website. The Profise Accounting Services may make any other change to the website and its content without prior notice. The Profise Accounting Services does not guarantee the accuracy, reliability, or completeness of any statement, opinion, or any other information exhibited or dispersed through this website. </p>
                

                <h4 className='priv'><span>Privacy </span> Policy</h4>
                <p>You have read, understood, and agreed to our privacy policy's terms and conditions. </p>


                <h4 className='priv'><span>Indemnity  </span></h4>
                <p>The Profise Accounting Services, its subsidiaries, and affiliates shall be held harmless and indemnified from any judgment, expense, claim, cost, or any other loss in relation to your use of this website. This also includes, without the preceding limitation, any action you might take that will violate the terms listed in The Profise Accounting Services terms of use. </p>
            

                <h4 className='priv'><span>No </span>Warranties</h4>
                <p>This website and all the information are provided without warranty, representation, expression, or implied. This also includes, but is not limited to, warranties of merchantability, non-infringement, or fitness for any specific purpose. There is no expressed or implied warranty regarding third-party content. There is also no warranty that this website will be free from computer viruses. If your jurisdiction does not entertain the prohibition of implied warranties, the above exclusions may not apply to you. </p>

                <h4 className='priv'><span>Limitation  </span>of Liability</h4>
                <p>On no occasion will The Profise Accounting Services be liable to any party for any special, incidental, exemplary, indirect, direct, or consequential damages of any type either related to or arising from this website, from any use of this website, any website or resource referenced, lined or accessed from this website and downloading and accessing information, products, materials, and services. This would include, without limitation, business disruptions and profits, lost savings, programs, or any other type of data. This limited liability applies to all actions and causes based on tort, contract, warranty, or other legal theories. </p>
                
                <h4 className='priv'><span>Third-Party </span>Content</h4>
                <p>This website makes the information of third parties available. This includes reports, articles, news, reports, and data from financial markets. By using this website, you acknowledge and consent that third-party content is neither created nor endorsed by The Profise Accounting Services. Third-party content is not meant to provide investment, tax, or legal advice. You admit that The Profise Accounting Services provides no guarantee for third-party content's reliability, accuracy, and completeness. You acknowledge not holding this website or any third-party content providers liable for liabilities, investment decisions, or transactions. </p>

                <h4 className='priv'><span>Limited </span>License</h4>
                <p>Regarding The Profise Accounting Services' terms of use, The Profise Permits a non-transferable and non-exclusive limited right to access and use the information on this website. You concede not to cause or attempt to cause any interruption to the functioning of this website. </p>

              </div>
            </div>

          </div>
        </div>
        </section>
        <ErpAction1/>
        <FooterFour />
        </div>
    )
}
export default Terms;