import React from 'react';
import CustomNavbar from '../components/CustomNavbar';
import truck from '../img/home2/truck.jpg';
import chartaccount from '../img/home2/chartaccount.png';
import {Link} from 'react-router-dom';
import featurefiveicon1 from '../img/home2/accounting1.png';
import featurefiveicon2 from '../img/home2/network1.png';
import featurefiveicon3 from '../img/home2/checklist1.png';
import featurefiveicon4 from '../img/home2/social-media1.png';
import ErpAction1 from '../components/ErpAction1';
import FooterFour from '../components/Footer/FooterFour';
import { Helmet } from 'react-helmet';


const Onboarding = () => {
    return(
        <div className="body_wrapper">
        <Helmet>
        <title>Best Accounting Services For Trucking Firms – The Profise</title>
    <meta name="title" content="Best Accounting Services For Trucking Firms – The Profise" />
    <meta name="description" content="The Profise can provide valuable support to the trucking industry, helping them manage their finances and make informed decisions about their business." />
        </Helmet>
            <CustomNavbar slogo="sticky_logo" mClass="menu_four" nClass="w_menu ml-auto mr-auto"/>
            <section className='trucking'>
        <div className='container'>
            <div className='row'>
            <div className='col-md-8 col-xxl-6 col-xl-6'>
              <h2>Business solutions <span class="color-green1"> for Trucking firms </span></h2>
                </div>   
                
            </div>
        </div>
            </section>
            <section className='abouttwo'>
            <div className='container'>
                <div className='row'>
                <div className='col-xxl-6 col-xl-6 col-md-6 col-12'>
                <img src={truck} className="aboutfirst" />
                
                </div>
                <div className='col-xxl-6 col-xl-6 col-md-6 col-12'>
                 <h2> <span className="color-green-900">Optimize </span>  your workflow with The Profise</h2>
                 <p className='Profitable'>We provide business solutions for trucking industries from short to long-term projects.</p>
                 <br></br>
                 <p>Our experts are here to help independent contractors, owner-operators, trucking businessmen and fleet owners in handling their finances and focus on other major duties.</p>
                 <Link to="/About-us" className="z-btn z-btn-border">ABOUT US</Link>
                </div>
                </div>
            </div>
            </section> 
            <section className="purple">
   <div className="container">
    <div className="row">
      <div className="col-lg-6 col-xxl-6 col-md-12 col-xxl-6 col-12">
        <div className="basic">
<h2><span className="color-green-900">Make </span> the process automated & accurate </h2>
<div className="mke">
<p>The operating model we have is strong enough to handle your bulk finances and our global workforce is here help you streamline your business processes, increase productivity and reduce overall expenses. </p>
<p>Technology and innovations are making it imperative for businesses to operate digitally. It is helping them to reach the masses and grow smartly. Our automated systems do the same for trucking firms. </p>
</div>
        </div>
      </div>
      <div className="col-lg-6 col-xxl-6 col-xxl-6 col-12">
        <div className="basic2">
        <img src={chartaccount} />
        </div>
      </div>
    </div>
   </div>

            </section>  
            <section className="freature-section-six pt-120 rpt-90 pb-90 rpb-60">
        <div className="container">
          <div className="row large-gap mb-30">
            <div className="col-lg-6">
              <div className="freature-six-left wow fadeInUp delay-0-2s">
                <div className="section-title mb-30">
                  <h2> <span className="color-green-900">Overcome </span>business challenges seamlessly</h2>       
                </div>
              </div>
            </div>
            <div className="col-lg-5">
              <div className="freature-six-right pt-55 rpt-0 wow fadeInUp delay-0-4s">
                <p>
                Your search for the most cost-effective business solutions ends with us. We make sure you earn the most returns and get the most minor burdens.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-3 col-sm-6">
              <div className="feature-six-item mt-30 wow fadeInUp delay-0-2s">
                <div className="content">
                  <div className="icon">
                  <img src={featurefiveicon1} />
                   
                  </div>
                  <h5 className='payable'>Accounting</h5>
                                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="feature-six-item wow fadeInUp delay-0-4s">
                <div className="content">
                  <div className="icon">
                  <img src={featurefiveicon2} />
                  </div>
                  <h5 className='payable'>Staffing</h5>
                                 </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="feature-six-item mt-30 wow fadeInUp delay-0-6s">
                <div className="content">
                  <div className="icon">
                  <img src={featurefiveicon3} />
                  </div>
                  <h5 className='payable'>Data Entry</h5>
                                 </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="feature-six-item wow fadeInUp delay-0-8s">
                <div className="content">
                  <div className="icon">
                  <img src={featurefiveicon4} />
                  </div>
                  <h5 className='payable'>Digital Marketing </h5>
                                 </div>
              </div>
            </div>
          </div>
        </div>
            </section>
           <ErpAction1/>
           <FooterFour />
           </div>
    )
}
export default Onboarding;