import React from 'react';
import CustomNavbar from '../components/CustomNavbar';
import featurefiveicon1 from '../img/home2/recruitment.png';
import featurefiveicon2 from '../img/home2/counting.png';
import featurefiveicon3 from '../img/home2/job-search.png';
import featurefiveicon4 from '../img/home2/contract.png';
import Staffing1 from '../img/home2/Staffing1.png';
import Staffing2 from '../img/home2/Staffing2.png';
import ErpAction1 from '../components/ErpAction1';
import Reveal from 'react-reveal';
import FooterFour from '../components/Footer/FooterFour';
import { Helmet } from 'react-helmet';


const Staffing =()=>{
    
    return(
        <div className="body_wrapper">
        <Helmet>
        <title>Accounting Finance Staffing Services Agency – The Profise</title>
    <meta name="title" content="Accounting Finance Staffing Services Agency – The Profise" />
    <meta name="description" content="The Profise helps in recruiting, hiring, and managing employees within a company which is essential for the company's success." />
        </Helmet>
        <CustomNavbar slogo="sticky_logo" mClass="menu_four" nClass="w_menu ml-auto mr-auto"/>

        <section className='staffbanner'>
          <div className='container'>
            <div className='row'>
            <div className='col-md-8 col-lg-6 col-xxl-6 col-xl-6'>
              <h2>Find <span class="color-green1">the Right Team for your firm</span></h2>
            </div> 
            </div>
          </div>
        </section>
        <section className="freature-section-six pt-120 rpt-90 pb-90 rpb-60">
        <div className="container">
          <div className="row large-gap mb-30">
            <div className="col-xl-6 col-lg-6 col-sm-6">
              <div className="freature-six-left wow fadeInUp delay-0-2s">
                <div className="section-title mb-30">
                  <h2> <span className="color-green-900">Staffing - </span>services we offer </h2>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-sm-6">
            <div className="freature-six-right pt-55 rpt-0 wow fadeInUp delay-0-4s">
                <p className='righttext-wh'>
                If you are struggling in finding the right staff for your firm, staffing helps you there. 
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-3 col-sm-6">
              <div className="feature-six-item mt-30 wow fadeInUp delay-0-2s">
                <div className="content">
                  <div className="icon">
                  <img src={featurefiveicon1} alt="" />
                   
                  </div>
                  <h5 className='payable-serv'>Recruitment</h5>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="feature-six-item wow fadeInUp delay-0-4s">
                <div className="content">
                  <div className="icon">
                  <img src={featurefiveicon2} alt="" />
                  </div>
                  <h5 className='payable-serv'>Selection</h5>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="feature-six-item mt-30 wow fadeInUp delay-0-6s">
                <div className="content">
                  <div className="icon">
                  <img src={featurefiveicon3} alt="" />
                  </div>
                  <h5 className='payable-serv'>Employment </h5>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="feature-six-item wow fadeInUp delay-0-8s">
                <div className="content">
                  <div className="icon">
                  <img src={featurefiveicon4} alt="" />
                  </div>
                  <h5 className='payable-serv'>Retaining </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
        </section>
        <section className="purple">
   <div className="container">
    <div className="row">
      <div className="col-xl-6 col-lg-6 col-sm-6">
        <div className="basic">
<h2><span class="acct">Staffing -</span> What is it?</h2>
<div className="mke">
<p>It is the process of finding, evaluating, selecting and maintaining a working relationship with employees (present and future). The major aim of staffing is to find suitable candidates for every vacancy. </p>
<p>The Profise can take over the burden of searching for candidates, so you can focus on other important tasks of your business. </p>
</div>
        </div>
      </div>
      <div className="col-xl-6 col-lg-6 col-sm-6">
      <div className="fromback">
  <h3>Send Your Inquires</h3>
  <form
    action="fileUploadScript.php"
    method="post"
    encType="multipart/form-data"
  >
  <div className='row'>
    <div className='col-xxl-6 col-xl-6 col-md-12 col-12'>
    <label>Legal Company Name:*</label>
    <input type="text" name="name" className="form-control" required="" />
    </div>
    <div className='col-xxl-6 col-xl-6 col-md-12 col-12'>
    <label>Your Email:*</label>
    <input type="email" name="email" className="form-control" required="" />
    </div>
    <div className='col-xxl-6 col-xl-6 col-md-12 col-12'>
    <label>Your Number:*</label>
    <input type="tel" name="phone" className="form-control" required="" />
    </div>
    <div className='col-xxl-6 col-xl-6 col-md-12 col-12'>
    <label>Location</label>
    <input type="text" name="namentitye" className="form-control" required="" />
    </div>
    <div className='col-xxl-12 col-xl-12 col-md-12 col-12'>
    <label>Your Query</label>
    <textarea name="message" rows="4" class="form-control"> </textarea>
    </div>
       </div>
         <br></br>
    <input type="submit" className="form-rol" />
  </form>
</div>
      </div>
    </div>
   </div>

        </section>
        <section className='abouttwo'>
            <div className='container'>
                <div className='row' id='Profitable'>
                <div className='col-xxl-6 col-xl-6 col-md-6 col-12 girl text-center'>
                <img src={Staffing1} alt="" />
                 </div>
                <div className='col-xxl-6 col-xl-6 col-md-6 col-12'>
                 <h2> <span className="color-green-900">Benefits</span> of staffing </h2>
                 <p>The most effective staffing process includes the following benefits:</p>
                 <br></br>
                 <ul class="Passion">
                    <li><span><i class="fa fa-angle-right" aria-hidden="true"></i></span>It will give you the right people at the right time.</li>
                    <li><span><i class="fa fa-angle-right" aria-hidden="true"></i></span>It increases organizational productivity. </li>
                    <li><span><i class="fa fa-angle-right" aria-hidden="true"></i></span>It gives job satisfaction to employees. </li>
                    <li><span><i class="fa fa-angle-right" aria-hidden="true"></i></span>It helps in maintaining company harmony.</li>
                 </ul>
                </div>

                </div>

                     </div>
        </section>
        <section className="get_started_area">
            <div className="container">
                <div className="row pb-20">
                    <div className="col-xl-5 col-lg-6 col-md-6 d-flex align-items-center">
                        <Reveal bottom cascade>
                            <div className="get_content">
                                <h2 className="l_height50 mb_20 wow fadeInLeft" data-wow-delay="0.3s"><span className='acct'>The Staffing </span>process </h2>                              
                            
                            </div>
                        </Reveal>
                    </div>
                    <div className="col-xl-7 col-lg-6 col-md-6 text-right">
                       <Reveal effect="fadeInRight" duration={2200}> 
                       <p className="righttext f_400 f_p mb_40 wow fadeInLeft" data-wow-delay="0.4s">There are multiple steps required when staffing is initiated but here we have four major steps that our staffing procedure contains. </p>
                       </Reveal>
                    </div>
                </div>

                <div className="row">
                    <div className="col-xl-3 col-lg-3 col-md-6 d-flex align-items-center">
                        <Reveal bottom cascade>
                            <div className="get_content">
                            <Reveal effect="fadeInRight" duration={2200}> <img src={require('../img/home2/planning.png')} alt="" className='servimg'/></Reveal>
                            <h2 className="acc l_height50 mb_20 wow fadeInLeft" data-wow-delay="0.3s">Manpower planning </h2>                                 
                            </div>
                        </Reveal>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-6 d-flex align-items-center">
                        <Reveal bottom cascade>
                            <div className="get_content">
                            <Reveal effect="fadeInRight" duration={2200}> <img src={require('../img/home2/recruitmentt.png')} alt="" className='servimg'/></Reveal>
                            <h2 className="acc l_height50 mb_20 wow fadeInLeft" data-wow-delay="0.3s">Recruitment</h2>                                   
                            </div>
                        </Reveal>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-6 d-flex align-items-center">
                        <Reveal bottom cascade>
                            <div className="get_content">
                            <Reveal effect="fadeInRight" duration={2200}> <img src={require('../img/home2/placement.png')} alt="" className='servimg'/></Reveal>
                            <h2 className="acc l_height50 mb_20 wow fadeInLeft" data-wow-delay="0.3s">Placement</h2>                                          
                            </div>
                        </Reveal>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-6 d-flex align-items-center">
                        <Reveal bottom cascade>
                            <div className="get_content">
                            <Reveal effect="fadeInRight" duration={2200}> <img src={require('../img/home2/development.png')} alt="" className='servimg'/></Reveal>
                            <h2 className="acc l_height50 mb_20 wow fadeInLeft" data-wow-delay="0.3s">Development</h2>                                    
                            </div>
                        </Reveal>
                    </div>
                </div>

            

            </div>
        </section>
        <section className="why-learn-area pb-120 rpb-100">
        <div className="container">
          <div className="row align-items-center large-gap wow fadeInLeft delay-0-2s">
            <div className="col-xl-6 col-lg-6 col-md-6">
              <div className="why-learn-content rmb-35">
                <div className="section-title mb-30">
                   <h2><span className="color-green-900">Why </span> are our staffing services different? </h2>
                </div>
                <p>With The Profise you have a lesser risk of hiring unsuitable candidates in your organisation. Additionally, we improve staffing quality by selecting people through multiple filters and ensure the employees selected are a boon for your business. </p>
                <p>We can help you hire staff for every kind of project whether it is full-time, part-time or deadline based. We reduce all of your liabilities and increase overall productivity. </p>
               
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 text-center">
              <div className="why-learn-image wow fadeInUp" data-wow-delay="0.4s">
               <img src={Staffing2} className="bluegirl" alt="" />
            
              </div>
            </div>
          </div>
        </div>
        </section>
        <ErpAction1/>
        <FooterFour />
        </div>
    )
}
export default Staffing;