import React from 'react';
import CustomNavbar from '../components/CustomNavbar';
import featurefiveicon1 from '../img/home2/invoices.png';
import featurefiveicon2 from '../img/home2/timelyinnv.png';
import featurefiveicon3 from '../img/home2/chargeback.png';
import featurefiveicon4 from '../img/home2/search.png';
import Accounts from '../img/home2/Accounts.png';
import AccountsReceivable22 from '../img/home2/AccountsReceivable22.png';
import ErpAction1 from '../components/ErpAction1';
import Reveal from 'react-reveal';
import FooterFour from '../components/Footer/FooterFour';
import { Helmet } from 'react-helmet';



const AccountsReceivable =()=>{
    return(
        <div className="body_wrapper">
        <Helmet>
        <title>Best Accounts Receivable Management Services Company</title>
    <meta name="title" content="Best Accounts Receivable Management Services Company" />
    <meta name="description" content="Accounts Receivable (AR) in The Profise analyses the money a company owes its customers for goods or services sold but not paid for." />
        </Helmet>
        <CustomNavbar slogo="sticky_logo" mClass="menu_four" nClass="w_menu ml-auto mr-auto"/>

        <section className='AccountsReceivable'>
          <div className='container'>
            <div className='row'>
            <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6'>
             <h2>Reclaim<span class="color-green1"> your lost working capital</span></h2>
            </div> 
            </div>
          </div>
        </section>
        <section className="freature-section-six pt-120 rpt-90 pb-90 rpb-60">
        <div className="container">
          <div className="row large-gap mb-30">
            <div className="col-xl-6 col-lg-6 col-sm-6">
              <div className="freature-six-left wow fadeInUp delay-0-2s">
                <div className="section-title mb-30">
                  <h2> <span className="color-green-900">Accounts receivable</span> - services we offer </h2>
                 </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-sm-6">
            <div className="freature-six-right pt-55 rpt-0 wow fadeInUp delay-0-4s">
                <p className='righttext-wh'>
                We help you improve billing management, reduce revenue dilution and costs and improve customer satisfaction through our accounts receivable support. <strong>Our key services for accounts receivable are: </strong></p>
                         </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-3 col-sm-6">
              <div className="feature-six-item mt-30 wow fadeInUp delay-0-2s">
                <div className="content">
                  <div className="icon">
                  <img src={featurefiveicon1} alt="" />
                   
                  </div>
                  <h5 className='payable-serv'>Accurate and timely invoicing.</h5>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="feature-six-item wow fadeInUp delay-0-4s">
                <div className="content">
                  <div className="icon">
                  <img src={featurefiveicon2} alt="" />
                  </div>
                  <h5 className='payable-serv'>Collection reporting and cash flow analysis.</h5>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="feature-six-item mt-30 wow fadeInUp delay-0-6s">
                <div className="content">
                  <div className="icon">
                  <img src={featurefiveicon3} alt="" />
                  </div>
                  <h5 className='payable-serv'>Reducing the chargebacks.</h5>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="feature-six-item wow fadeInUp delay-0-8s">
                <div className="content">
                  <div className="icon">
                  <img src={featurefiveicon4} alt="" />
                  </div>
                  <h5 className='payable-serv'>Analyzing and following up on all the accounts receivable. </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
         <section className="purple">
   <div className="container">
    <div className="row">
      <div className="col-xl-6 col-lg-6 col-sm-6">
        <div className="basic">
 <h2><span class="acct">Accounts </span> receivable- What Is It?</h2>
<div className="mke">
<p>The accounts receivable is the total amount of money the company has received from selling its products or services but not yet collected in cash. The process of managing accounts receivable includes taking steps to collect outstanding funds before they become delinquent.</p>
<p>It includes sending past-due invoices, generating collection calls, and pursuing legal action. It requires care, as aggressive collections practices may harm your business's customer relationship.</p>
</div>
        </div>
      </div>
      <div className="col-xl-6 col-lg-6 col-sm-6">
      <div className="fromback">
  <h3>Send Your Inquires</h3>
  <form
    action="fileUploadScript.php"
    method="post"
    encType="multipart/form-data"
  >
  <div className='row'>
    <div className='col-xxl-6 col-xl-6 col-md-12 col-12'>
    <label>Legal Company Name:*</label>
    <input type="text" name="name" className="form-control" required="" />
    </div>
    <div className='col-xxl-6 col-xl-6 col-md-12 col-12'>
    <label>Your Email:*</label>
    <input type="email" name="email" className="form-control" required="" />
    </div>
    <div className='col-xxl-6 col-xl-6 col-md-12 col-12'>
    <label>Your Number:*</label>
    <input type="tel" name="phone" className="form-control" required="" />
    </div>
    <div className='col-xxl-6 col-xl-6 col-md-12 col-12'>
    <label>Location</label>
    <input type="text" name="namentitye" className="form-control" required="" />
    </div>
    <div className='col-xxl-12 col-xl-12 col-md-12 col-12'>
    <label>Your Query</label>
    <textarea name="message" rows="4" class="form-control"> </textarea>
    </div>
       </div>
         <br></br>
    <input type="submit" className="form-rol" />
  </form>
</div>
      </div>
    </div>
   </div>

   </section>

      <section className='abouttwo'>
            <div className='container'>
                <div className='row' id='Profitable'>
                <div className='col-xxl-6 col-xl-6 col-md-6 col-12 girl text-center'>
                <img src={Accounts} alt="" />
                 </div>
                <div className='col-xxl-6 col-xl-6 col-md-6 col-12'>
                 <h2> <span className="color-green-900">How </span>does accounts receivable work? </h2>
                 <p className='Profitable'>The accounts receivable is the backbone of every business. Tracing and invoicing the accounts receivable on time is essential for every organization.</p>
                 <br></br>
                 <p>We at The Profise ensure that your working capital is never stuck; we will manage and complete your accounts timely. Our accounts receivable support lowers customers’ daily sales outstanding. </p>
                 <br></br>
            
                </div>

                </div>
                <div className='row' id='tripple'>
                <div className="col-lg-4">
                        <div className="media tracking_item">
                            <img src={require ("../img/home-tracking/next.png")} alt=""/>
                            <div className="media-body">
                                <h3 className="h_head">We take the list of customers who haven't paid for their stuff and call each one.</h3>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="media tracking_item">
                            <img src={require ("../img/home-tracking/next.png")} alt=""/>
                            <div className="media-body">
                                <h3 className="h_head">We have robust record-keeping on what you owe and what you are owed.</h3>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="media tracking_item">
                            <img src={require ("../img/home-tracking/next.png")} alt=""/>
                            <div className="media-body">
                               <h3 className="h_head">We do an annual cycle of checks and balances to ensure everything is accurate.
</h3>
                            </div>
                        </div>
                </div>
                </div>
                     </div>
           </section>
           <section className="get_started_area">
            <div className="container">
                <div className="row pb-20">
                    <div className="col-xl-5 col-lg-6 col-md-6 d-flex align-items-center">
                        <Reveal bottom cascade>
                            <div className="get_content">
                                <h2 className="l_height50 mb_20 wow fadeInLeft" data-wow-delay="0.3s"><span className='acct'>The </span>  accounts receivable process  </h2>                              
                            
                            </div>
                        </Reveal>
                    </div>
                    <div className="col-xl-7 col-lg-6 col-md-6 text-right">
                       <Reveal effect="fadeInRight" duration={2200}> 
                       <p className="righttext f_400 f_p mb_40 wow fadeInLeft" data-wow-delay="0.4s">Accounts Receivable is a method used to manage amounts due from customers. Accounts Receivable is one of the core part of any business. The accounts Receivable Management process improves cash flow for the company. The accounts receivable process varies from company to company. Here are the common steps which are involved in every business’s AR cycle: </p>
                       </Reveal>
                    </div>
                </div>

                <div className="row">
                    <div className="col-xl-3 col-lg-3 col-md-6 d-flex align-items-center">
                        <Reveal bottom cascade>
                            <div className="get_content">
                            <Reveal effect="fadeInRight" duration={2200}> <img src={require('../img/home2/onboarding.png')} alt="" className='servimg'/></Reveal>
                            <h2 className="acc l_height50 mb_20 wow fadeInLeft" data-wow-delay="0.3s">Customer Onboarding</h2>                                 
                            </div>
                        </Reveal>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-6 d-flex align-items-center">
                        <Reveal bottom cascade>
                            <div className="get_content">
                            <Reveal effect="fadeInRight" duration={2200}> <img src={require('../img/home2/order.png')} alt="" className='servimg'/></Reveal>
                            <h2 className="acc l_height50 mb_20 wow fadeInLeft" data-wow-delay="0.3s">Receive orders & send invoices</h2>                                   
                            </div>
                        </Reveal>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-6 d-flex align-items-center">
                        <Reveal bottom cascade>
                            <div className="get_content">
                            <Reveal effect="fadeInRight" duration={2200}> <img src={require('../img/home2/coin-collecting.png')} alt="" className='servimg'/></Reveal>
                            <h2 className="acc l_height50 mb_20 wow fadeInLeft" data-wow-delay="0.3s">Collecting payments </h2>                                          
                            </div>
                        </Reveal>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-6 d-flex align-items-center">
                        <Reveal bottom cascade>
                            <div className="get_content">
                            <Reveal effect="fadeInRight" duration={2200}> <img src={require('../img/home2/agreement.png')} alt="" className='servimg'/></Reveal>
                            <h2 className="acc l_height50 mb_20 wow fadeInLeft" data-wow-delay="0.3s">Cash reconciliation & posting</h2>                                    
                            </div>
                        </Reveal>
                    </div>
                </div>

            

            </div>
        </section>
        

      <section className="why-learn-area pb-120 rpb-100">
        <div className="container">
          <div className="row align-items-center large-gap wow fadeInLeft delay-0-2s">
            <div className="col-xl-6 col-lg-6 col-md-6">
              <div className="why-learn-content rmb-35">
                <div className="section-title mb-30">
                  <h2><span className="color-green-900"> Why are </span>  our accounts receivable services different? </h2>
                  <p className="Profitable">We take care of all your receivable accounts. To run a successful business, you need to manage your accounts receivable, and we can help you with this. </p>
                </div>
                <p><br></br>
                We offer invoice adjustments, customer invoicing, account reconciliation, credit control, and other accounting services. We use quality improvement programs to make sure to provide you with high-quality services. 
                </p>
                <p>
                We use different data security measures to ensure the confidentiality of your data. Our accounts receivable services provide faster payments. 

                </p>
               
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 text-center">
              <div className="why-learn-image wow fadeInUp" data-wow-delay="0.4s">
               <img src={AccountsReceivable22} className="bluegirl" alt="" />
            
              </div>
            </div>
          </div>
        </div>
      </section>
    
     
    
            <ErpAction1/>
      <FooterFour />
                  </div>
    )
}
export default AccountsReceivable;