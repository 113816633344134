import React from 'react';
import CustomNavbar from '../components/CustomNavbar';
import featurefiveicon1 from '../img/home2/track-tax.png';
import featurefiveicon2 from '../img/home2/tax-form.png';
import featurefiveicon3 from '../img/home2/deposit-tax.png';
import featurefiveicon4 from '../img/home2/sales-tax.png';
import growth from '../img/home2/growth.png';
import Tax from '../img/home2/Tax.png';
import TaxComplianceFilings22 from '../img/home2/TaxComplianceFilings22.png';
import ErpAction1 from '../components/ErpAction1';
import Reveal from 'react-reveal';
import FooterFour from '../components/Footer/FooterFour';
import { Helmet } from 'react-helmet';



const TaxComplianceFilings =()=>{
    return(
        <div className="body_wrapper">
        <Helmet>
        <title>Best Business Tax Compliance Services – The Profise</title>
    <meta name="title" content="Best Business Tax Compliance Services – The Profise" />
    <meta name="description" content="Tax compliance in The Profise will meet a company's tax obligations, including the timely and accurate filing of tax returns and the payment of taxes owed." />
        </Helmet>
        <CustomNavbar slogo="sticky_logo" mClass="menu_four" nClass="w_menu ml-auto mr-auto"/>

        <section className='TaxCompliancFilings'>
          <div className='container'>
            <div className='row'>
            <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6'>
             <h2>Enhance<span class="color-green1"> your tax filings </span></h2>
            </div> 
            </div>
          </div>
        </section>
        <section className="freature-section-six pt-120 rpt-90 pb-90 rpb-60">
        <div className="container">
          <div className="row large-gap mb-30">
            <div className="col-xl-6 col-lg-6 col-sm-6">
              <div className="freature-six-left wow fadeInUp delay-0-2s">
                <div className="section-title mb-30">
                <h2> <span className="color-green-900">Tax compliance</span> and filings - services we Offer </h2>
           
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-sm-6">
            <div className="freature-six-right pt-55 rpt-0 wow fadeInUp delay-0-4s">
                <p className='righttext-wh'>
                We at The Profise offer an integrated and complete solution for adherence to federal and state laws and taxes. 
                Our key services for tax compliance and filing are:
                </p>
                         </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-3 col-sm-6">
              <div className="feature-six-item mt-30 wow fadeInUp delay-0-2s">
                <div className="content">
                  <div className="icon">
                  <img src={featurefiveicon1} alt="" />
                   
                  </div>
                  <h5 className='payable-serv'>Tracking the latest tax provisions.</h5>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="feature-six-item wow fadeInUp delay-0-4s">
                <div className="content">
                  <div className="icon">
                  <img src={featurefiveicon2} alt="" />
                  </div>
                  <h5 className='payable-serv'>Preparing monthly and annual tax forms.</h5>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="feature-six-item mt-30 wow fadeInUp delay-0-6s">
                <div className="content">
                  <div className="icon">
                  <img src={featurefiveicon3} alt="" />
                  </div>
                  <h5 className='payable-serv'>Depositing taxes on time. </h5>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="feature-six-item wow fadeInUp delay-0-8s">
                <div className="content">
                  <div className="icon">
                  <img src={featurefiveicon4} alt="" />
                  </div>
                  <h5 className='payable-serv'>Setting up the sales tax system. </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
        </section>
        <section className="purple">
   <div className="container">
    <div className="row">
      <div className="col-xl-6 col-lg-6 col-sm-6">
        <div className="basic">
        <h2><span class="acct">Tax</span>  compliance and filings - what is it? </h2>
<div className="mke">
<p>Tax compliance is referred to the taxpayers’ decision to comply with tax regulations and laws by paying the tax accurately and on time. On the other hand, tax filing means submitting tax forms or tax returns to the appropriate government agencies.</p>
<p>Two main types of compliance are regulatory and corporate compliance. Both of these compliance consists of a framework of regulations, rules and practices to follow. </p>
</div>
        </div>
      </div>
      <div className="col-xl-6 col-lg-6 col-sm-6">
      <div className="fromback">
  <h3>Send Your Inquires</h3>
  <form
    action="fileUploadScript.php"
    method="post"
    encType="multipart/form-data"
  >
  <div className='row'>
    <div className='col-xxl-6 col-xl-6 col-md-12 col-12'>
    <label>Legal Company Name:*</label>
    <input type="text" name="name" className="form-control" required="" />
    </div>
    <div className='col-xxl-6 col-xl-6 col-md-12 col-12'>
    <label>Your Email:*</label>
    <input type="email" name="email" className="form-control" required="" />
    </div>
    <div className='col-xxl-6 col-xl-6 col-md-12 col-12'>
    <label>Your Number:*</label>
    <input type="tel" name="phone" className="form-control" required="" />
    </div>
    <div className='col-xxl-6 col-xl-6 col-md-12 col-12'>
    <label>Location</label>
    <input type="text" name="namentitye" className="form-control" required="" />
    </div>
    <div className='col-xxl-12 col-xl-12 col-md-12 col-12'>
    <label>Your Query</label>
    <textarea name="message" rows="4" class="form-control"> </textarea>
    </div>
       </div>
         <br></br>
    <input type="submit" className="form-rol" />
  </form>
</div>
      
      </div>
    </div>
   </div>

        </section>
        <section className='abouttwo'>
            <div className='container'>
                <div className='row' id='Profitable'>
                <div className='col-xxl-6 col-xl-6 col-md-6 col-12 girl text-center'>
                <img src={Tax}  alt="" />
                 </div>
                <div className='col-xxl-6 col-xl-6 col-md-6 col-12'>
                   <h2> <span className="color-green-900"> How does </span> tax compliance and filings work? </h2>
                 <p className='Profitable'>The more inventory, revenue, or fixed assets you have, the more complicated your taxes will get. </p>
                 <br></br>
                 <p>A business tax is determined by the structure they follow. To ensure compliance with federal or state laws and to maximise their benefits, you will need experience and knowledge like us. </p>
                 <br></br>
            
                </div>

                </div>
                <div className='row' id='tripple'>
                <div className="col-lg-4">
                        <div className="media tracking_item">
                            <img src={require ("../img/home-tracking/next.png")} alt=""/>
                            <div className="media-body">
                                <h3 className="h_head">We prepare your tax <br></br>forms.</h3>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="media tracking_item">
                            <img src={require ("../img/home-tracking/next.png")} alt=""/>
                            <div className="media-body">
                                <h3 className="h_head">We will deposit your  <br></br>taxes timely.</h3>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="media tracking_item">
                            <img src={require ("../img/home-tracking/next.png")} alt=""/>
                            <div className="media-body">
                               <h3 className="h_head">We will take care of paying  <br></br>your taxes.</h3>
                            </div>
                        </div>
                </div>
                </div>
                     </div>
        </section>
        <section className="get_started_area">
            <div className="container">
                <div className="row pb-20">
                    <div className="col-xl-5 col-lg-6 col-md-6 d-flex align-items-center">
                        <Reveal bottom cascade>
                            <div className="get_content">
                            
                                <h2 className="l_height50 mb_20 wow fadeInLeft" data-wow-delay="0.3s"><span className='acct'>The tax</span> compliance and filings process </h2>                              
                            
                            </div>
                        </Reveal>
                    </div>
                    <div className="col-xl-7 col-lg-6 col-md-6 text-right">
                       <Reveal effect="fadeInRight" duration={2200}> 
                       <p className="righttext f_400 f_p mb_40 wow fadeInLeft" data-wow-delay="0.4s">Businesses need to file their taxes on time. If you have just started your business and do not know about tax compliance and the filing process, we can help you with this.  </p>
                       </Reveal>
                    </div>
                </div>

                <div className="row">
                    <div className="col-xl-3 col-lg-3 col-md-6 d-flex align-items-center">
                        <Reveal bottom cascade>
                            <div className="get_content">
                            <Reveal effect="fadeInRight" duration={2200}> <img src={require('../img/home2/paper.png')} alt="" className='servimg'/></Reveal>
                            <h2 className="acc l_height50 mb_20 wow fadeInLeft" data-wow-delay="0.3s">Gather all the required documents.</h2>                                 
                            </div>
                        </Reveal>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-6 d-flex align-items-center">
                        <Reveal bottom cascade>
                            <div className="get_content">
                            <Reveal effect="fadeInRight" duration={2200}> <img src={require('../img/home2/tax-buss.png')} alt="" className='servimg'/></Reveal>
                            <h2 className="acc l_height50 mb_20 wow fadeInLeft" data-wow-delay="0.3s">Determine your business structure’s tax form.</h2>                                   
                            </div>
                        </Reveal>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-6 d-flex align-items-center">
                        <Reveal bottom cascade>
                            <div className="get_content">
                            <Reveal effect="fadeInRight" duration={2200}> <img src={require('../img/home2/payment-method.png')} alt="" className='servimg'/></Reveal>
                            <h2 className="acc l_height50 mb_20 wow fadeInLeft" data-wow-delay="0.3s">Know about deadlines, payment options, etc.</h2>                                          
                            </div>
                        </Reveal>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-6 d-flex align-items-center">
                        <Reveal bottom cascade>
                            <div className="get_content">
                            <Reveal effect="fadeInRight" duration={2200}> <img src={require('../img/home2/consultation.png')} alt="" className='servimg'/></Reveal>
                            <h2 className="acc l_height50 mb_20 wow fadeInLeft" data-wow-delay="0.3s">Consult with our experts for applying returns. </h2>                                    
                            </div>
                        </Reveal>
                    </div>
                </div>

            

            </div>
        </section>
        <section className="why-learn-area pb-120 rpb-100">
        <div className="container">
          <div className="row align-items-center large-gap wow fadeInLeft delay-0-2s">
            <div className="col-xl-6 col-lg-6 col-md-6">
              <div className="why-learn-content rmb-35">
                <div className="section-title mb-30">
                 <h2><span className="color-green-900"> Why are </span> our tax compliance and filings services different? </h2>
                  <p className="Profitable">Engage with our professional tax preparation services to enhance your tax filing efficiently. We will find thousands of taxes for you, so you don’t need to pay more. </p>
                </div>
                <p>
                Our experts schedule all the necessary forms ranging from preparation and filing. They ensure that all the required information is filed on time and in a proper format to avoid compliance-related issues. Be it monthly payroll filings or annual filings.
                </p>
                <p>
                Our experienced staff converses with all the state and federal laws and will ensure all the filings are on time and correct. We will keep track of all your latest tax provisions. 

                </p>
               
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 text-center">
              <div className="why-learn-image wow fadeInUp" data-wow-delay="0.4s">
               <img src={TaxComplianceFilings22} className="bluegirl"  alt="" />
            
              </div>
            </div>
          </div>
        </div>
        </section>
        <ErpAction1/>
        <FooterFour />
        </div>
    )
}
export default TaxComplianceFilings;