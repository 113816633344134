import React from 'react';
import CustomNavbar from '../components/CustomNavbar';
import DesignBanner from '../components/Banner/DesignBanner';
import Service from '../components/Service/Service';
import Subscribe from '../components/Subscribe';
import Footer from '../components/Footer/Footer';
import FooterData from '../components/Footer/FooterData';
import ErpBanner from '../components/Banner/ErpBanner';
import AppGetstarted from '../components/AppGetstarted';
import Section1 from "../components/Features/Section1";
import ChatClients from "../components/chatClients";
import ErpAction from '../components/ErpAction';
import Section2 from '../components/Features/Section2';
import Partner from '../components/Partner';
import ErpAction1 from '../components/ErpAction1';
import FooterFour from '../components/Footer/FooterFour';
import { Helmet } from 'react-helmet';

export const Home = () => (
    <div className="body_wrapper">
<Helmet>
    <title>Best Accounting Service Provider Agency – The Profise</title>
    <meta name="title" content="Best Accounting Service Provider Agency – The Profise" />
    <meta name="description" content="Skip handling your business finances yourself; choose The Profise. Our accounting services will take off your major loads so you can focus on the rest." />
                    </Helmet>
         <CustomNavbar slogo="sticky_logo" mClass="menu_four" nClass="w_menu ml-auto mr-auto"/>
         <ErpBanner />
        <Section1/>
        <AppGetstarted/>
        <ChatClients/>
        <ErpAction/>  
        <Section2/>
        <Partner pClass="partner_logo_area_five bg_color"/>
        <ErpAction1/>
        <FooterFour />
      
    </div>
)