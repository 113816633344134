import React from 'react';
import CustomNavbar from '../components/CustomNavbar';
import featurefiveicon1 from '../img/home2/bookkeeping.png';
import featurefiveicon2 from '../img/home2/membership.png';
import featurefiveicon3 from '../img/home2/time.png';
import featurefiveicon4 from '../img/home2/solution.png';
import bookkeeping1 from '../img/home2/bookkeeping1.png';
import bookkeeping2 from '../img/home2/bookkeeping2.png';
import ErpAction1 from '../components/ErpAction1';
import Reveal from 'react-reveal';
import TrackingActivity from '../components/TrackingActivity';
import FooterFour from '../components/Footer/FooterFour';
import { Helmet } from 'react-helmet';



const Bookkeeping =()=>{
    
    return(
        <div className="body_wrapper">
        <Helmet>
        <title>Best Outsourced QuickBooks Bookkeeping Services – The Profise</title>
    <meta name="title" content="Best Outsourced QuickBooks Bookkeeping Services – The Profise" />
    <meta name="description" content="The Profise accurately records a company's financial transactions, including sales, purchases, receipts, etc., in a systematic and organized manner." />
        </Helmet>
        <CustomNavbar slogo="sticky_logo" mClass="menu_four" nClass="w_menu ml-auto mr-auto"/>

        <section className='bookkeeping'>
          <div className='container'>
            <div className='row'>
            <div className='col-md-8 col-lg-6 col-xxl-6 col-xl-6'>
              <h2>Make your <span class="color-green1"> finances more organized </span></h2>
            </div> 
            </div>
          </div>
        </section>
        <section className="freature-section-six pt-120 rpt-90 pb-90 rpb-60">
        <div className="container">
          <div className="row large-gap mb-30">
            <div className="col-xl-6 col-lg-6 col-sm-6">
              <div className="freature-six-left wow fadeInUp delay-0-2s">
                <div className="section-title mb-30">
                  <h2> <span className="color-green-900">Bookkeeping - </span>services we offer </h2>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-sm-6">
            <div className="freature-six-right pt-55 rpt-0 wow fadeInUp delay-0-4s">
                <p className='righttext-wh'>
                It doesn’t matter how complex your bookkeeping requirements are; our extensive financial knowledge makes us serve you as your tax planners and accounting advisors. 
Our bookkeeping services are: 
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-3 col-sm-6">
              <div className="feature-six-item mt-30 wow fadeInUp delay-0-2s">
                <div className="content">
                  <div className="icon">
                  <img src={featurefiveicon1} alt="" />
                   
                  </div>
                  <h5 className='payable-serv'>Balancing your books</h5>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="feature-six-item wow fadeInUp delay-0-4s">
                <div className="content">
                  <div className="icon">
                  <img src={featurefiveicon2} alt="" />
                  </div>
                  <h5 className='payable-serv'>Deliver quality services</h5>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="feature-six-item mt-30 wow fadeInUp delay-0-6s">
                <div className="content">
                  <div className="icon">
                  <img src={featurefiveicon3} alt="" />
                  </div>
                  <h5 className='payable-serv'>Track your income and expenses</h5>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="feature-six-item wow fadeInUp delay-0-8s">
                <div className="content">
                  <div className="icon">
                  <img src={featurefiveicon4} alt="" />
                  </div>
                  <h5 className='payable-serv'>Provide custom solutions</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
        </section>
        <section className="purple">
   <div className="container">
    <div className="row">
      <div className="col-xl-6 col-lg-6 col-sm-6">
        <div className="basic">
<h2><span class="acct">Bookkeeping -</span> what is it? </h2>
<div className="mke">
<p>Bookkeeping is an essential part of business accounting; it is the process of recording all your company’s financial transactions in an organized account daily.</p>
<p>We keep your transaction records updated, so you can generate accurate financial reports which will help you measure your business performance. </p>
</div>
        </div>
      </div>
      <div className="col-xl-6 col-lg-6 col-sm-6">
      <div className="fromback">
  <h3>Send Your Inquires</h3>
  <form
    action="fileUploadScript.php"
    method="post"
    encType="multipart/form-data"
  >
  <div className='row'>
    <div className='col-xxl-6 col-xl-6 col-md-12 col-12'>
    <label>Legal Company Name:*</label>
    <input type="text" name="name" className="form-control" required="" />
    </div>
    <div className='col-xxl-6 col-xl-6 col-md-12 col-12'>
    <label>Your Email:*</label>
    <input type="email" name="email" className="form-control" required="" />
    </div>
    <div className='col-xxl-6 col-xl-6 col-md-12 col-12'>
    <label>Your Number:*</label>
    <input type="tel" name="phone" className="form-control" required="" />
    </div>
    <div className='col-xxl-6 col-xl-6 col-md-12 col-12'>
    <label>Location</label>
    <input type="text" name="namentitye" className="form-control" required="" />
    </div>
    <div className='col-xxl-12 col-xl-12 col-md-12 col-12'>
    <label>Your Query</label>
    <textarea name="message" rows="4" class="form-control"> </textarea>
    </div>
       </div>
         <br></br>
    <input type="submit" className="form-rol" />
  </form>
</div>
      </div>
    </div>
   </div>

        </section>
        <section className='abouttwo'>
            <div className='container'>
                <div className='row' id='Profitable'>
                <div className='col-xxl-6 col-xl-6 col-md-6 col-12 girl text-center'>
                <img src={bookkeeping1} alt="" />
                 </div>
                <div className='col-xxl-6 col-xl-6 col-md-6 col-12'>
                 <h2> <span className="color-green-900">Benefits </span> of bookkeeping </h2>
                 <p>Bookkeeping is the first step of the accounting process and also the essential one. Our bookkeepers will compile all your financial information, from transactions to your wages, and convert them into a readable report which you can use for future analysis. The benefits of having a good bookkeeper are: </p>
                 <br></br>
                 <ul class="Passion">
                    <li><span><i class="fa fa-angle-right" aria-hidden="true"></i></span>Detailed records are managed.</li>
                    <li><span><i class="fa fa-angle-right" aria-hidden="true"></i></span>Easier to plan and predict the future.</li>
                    <li><span><i class="fa fa-angle-right" aria-hidden="true"></i></span>Updated balance sheet. </li>
                 </ul>
                </div>

                </div>

                     </div>
        </section>
        <section className="get_started_area">
            <div className="container">
                <div className="row pb-20">
                    <div className="col-xl-5 col-lg-6 col-md-6 d-flex align-items-center">
                        <Reveal bottom cascade>
                            <div className="get_content">
                                <h2 className="l_height50 mb_20 wow fadeInLeft" data-wow-delay="0.3s"><span className='acct'>The Bookkeeping </span>Process </h2>                              
                            
                            </div>
                        </Reveal>
                    </div>
                    <div className="col-xl-7 col-lg-6 col-md-6 text-right">
                       <Reveal effect="fadeInRight" duration={2200}> 
                       <p className="righttext f_400 f_p mb_40 wow fadeInLeft" data-wow-delay="0.4s">The bookkeeping process allows you to store all your financial information from the opening to the closing of the business. The bookkeeping process includes the following:
</p>
                       </Reveal>
                    </div>
                </div>

                <div className="row">
                    <div className="col-xl-3 col-lg-3 col-md-6 d-flex align-items-center">
                        <Reveal bottom cascade>
                            <div className="get_content">
                            <Reveal effect="fadeInRight" duration={2200}> <img src={require('../img/home2/system.png')} alt="" className='servimg'/></Reveal>
                            <h2 className="acc l_height50 mb_20 wow fadeInLeft" data-wow-delay="0.3s">Understanding and maintaining the business accounts. </h2>                                 
                            </div>
                        </Reveal>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-6 d-flex align-items-center">
                        <Reveal bottom cascade>
                            <div className="get_content">
                            <Reveal effect="fadeInRight" duration={2200}> <img src={require('../img/home2/plan.png')} alt="" className='servimg'/></Reveal>
                            <h2 className="acc l_height50 mb_20 wow fadeInLeft" data-wow-delay="0.3s">Recording all your business transactions in one place.</h2>                                   
                            </div>
                        </Reveal>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-6 d-flex align-items-center">
                        <Reveal bottom cascade>
                            <div className="get_content">
                            <Reveal effect="fadeInRight" duration={2200}> <img src={require('../img/home2/balancing.png')} alt="" className='servimg'/></Reveal>
                            <h2 className="acc l_height50 mb_20 wow fadeInLeft" data-wow-delay="0.3s">Balancing the books to check debits and credits are equal.</h2>                                          
                            </div>
                        </Reveal>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-6 d-flex align-items-center">
                        <Reveal bottom cascade>
                            <div className="get_content">
                            <Reveal effect="fadeInRight" duration={2200}> <img src={require('../img/home2/finan-statement.png')} alt="" className='servimg'/></Reveal>
                            <h2 className="acc l_height50 mb_20 wow fadeInLeft" data-wow-delay="0.3s">Preparing the financial statements to summarize the flow of money.</h2>                                    
                            </div>
                        </Reveal>
                    </div>
                </div>

            

            </div>
        </section>
        <section className="why-learn-area pb-120 rpb-100">
        <div className="container">
          <div className="row align-items-center large-gap wow fadeInLeft delay-0-2s">
            <div className="col-xl-6 col-lg-6 col-md-6">
              <div className="why-learn-content rmb-35">
                <div className="section-title mb-30">
                   <h2><span className="color-green-900">Why </span> Are Our Bookkeeping Services Different? </h2>
                  <p className="Profitable">We at The Profise strive to provide you services on time as we understand that you work under tight deadlines and require timely services. </p>
                </div>
                <p>We are unique because we understand the importance of safeguarding your financial data and following stringent data protection policies to keep your data safe. We use the latest bookkeeping and financial accounting tools and technologies to work more efficiently. </p>
                <p>As a leading bookkeeping service, our company provides you with top-quality services. We provide our clients with customized bookkeeping services that cater to your needs. </p>
               
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 text-center">
              <div className="why-learn-image wow fadeInUp" data-wow-delay="0.4s">
               <img src={bookkeeping2} className="bluegirl" alt="" />
            
              </div>
            </div>
          </div>
        </div>
        </section>
        <ErpAction1/>
        <FooterFour />
        </div>
    )
}
export default Bookkeeping;