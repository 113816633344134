import React, { Component } from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";
/*------ Pages-----*/
import { Home } from "./Pages/Home";
import Process from "./Pages/Process";
import Bloglist from "./Pages/Bloglist";
import BlogSingle from "./Pages/BlogSingle";
import Contact from "./Pages/Contact";
import ScrollToTopRoute from "./ScrollToTopRoute";
import Price from "./Pages/Price";
import Faq from "./Pages/Faq";
import SignIn from "./Pages/SignIn";
import SignUp from "./Pages/SignUp";
import Blog from "./Pages/BlogGridPage";
import NotFound from "./Pages/404";
import Whychooseus from "./Pages/Whychooseus";
import Accountspayable from "./Pages/Accountspayable";
import Onboarding from "./Pages/On-boarding";
import Trucking from "./Pages/Trucking";
import Payroll from "./Pages/Payroll";
import MISFinancialStatementsPreparation from './Pages/MISFinancialStatementsPreparation';
import TaxComplianceandFilings from './Pages/TaxComplianceandFilings';
import Reconciliation from './Pages/Reconciliation';
import BudgetingForecasting from './Pages/BudgetingForecasting';
import AccountsReceivable from './Pages/AccountsReceivable';
import Aboutus from "./Pages/About-us";
import OurServices from "./Pages/OurServices";
import Privacypolicy from './Pages/Privacypolicy';
import Termsconditions from './Pages/Termsconditions';
import Staffing from "./Pages/Staffing";
import DataEntry from "./Pages/DataEntry";
import Websitedevelopment from "./Pages/Websitedevelopment";
import Digitalmarketing from "./Pages/Digitalmarketing";
import Bookkeeping from "./Pages/Bookkeeping";
import Invoicingandbilling from "./Pages/Invoicingandbilling";
import Healthcare from "./Pages/Healthcare";
import Manufacturing from "./Pages/Manufacturing";
import Others from "./Pages/Others";
import BlogGridPage from "./Pages/BlogGridPage";





class App extends Component {
  componentDidMount() {
    this.props.hideLoader();
  }
  render() {
    return (
      <Router>
        <Switch>
          <ScrollToTopRoute exact={true} path={"/"} component={Home} />
          <ScrollToTopRoute path="/Termsconditions" component={Termsconditions} />
          <ScrollToTopRoute path="/Privacypolicy" component={Privacypolicy} />
          <ScrollToTopRoute path="/OurServices" component={OurServices} />
           <ScrollToTopRoute path="/AccountsReceivable" component={AccountsReceivable} />
          <ScrollToTopRoute path="/BudgetingForecasting" component={BudgetingForecasting} />
          <ScrollToTopRoute path="/Reconciliation" component={Reconciliation} />
           <ScrollToTopRoute path="/TaxComplianceandFilings" component={TaxComplianceandFilings} />
          <ScrollToTopRoute path="/MISFinancialStatementsPreparation" component={MISFinancialStatementsPreparation} />
           <ScrollToTopRoute path="/Payroll" component={Payroll} />
           <ScrollToTopRoute path="/Trucking" component={Trucking} />
          <ScrollToTopRoute path="/On-boarding" component={Onboarding} />
           <ScrollToTopRoute path="/Accountspayable" component={Accountspayable} />
           <ScrollToTopRoute path="/Whychooseus" component={Whychooseus} />
           <ScrollToTopRoute path="/About-us" component={Aboutus} />
           <ScrollToTopRoute path="/Staffing" component={Staffing} />
           <ScrollToTopRoute path="/BlogGridPage" component={BlogGridPage} />
           <ScrollToTopRoute path="/DataEntry" component={DataEntry} />
           <ScrollToTopRoute path="/Websitedevelopment" component={Websitedevelopment} />
           <ScrollToTopRoute path="/Digitalmarketing" component={Digitalmarketing} />
           <ScrollToTopRoute path="/Bookkeeping" component={Bookkeeping} />
           <ScrollToTopRoute path="/Invoicingandbilling" component={Invoicingandbilling} />
           <ScrollToTopRoute path="/Healthcare" component={Healthcare} />
           <ScrollToTopRoute path="/Manufacturing" component={Manufacturing} />
           <ScrollToTopRoute path="/Others" component={Others} />

        
           <ScrollToTopRoute path="/Process" component={Process} />
           <ScrollToTopRoute path="/Bloglist" component={Bloglist} />
          <ScrollToTopRoute path="/BlogSingle" component={BlogSingle} />
          <ScrollToTopRoute path="/Blog" component={Blog} />
          <ScrollToTopRoute path="/Contact" component={Contact} />
          <ScrollToTopRoute path="/Price" component={Price} />
          <ScrollToTopRoute path="/Faq" component={Faq} />
          <ScrollToTopRoute path="/SignIn" component={SignIn} />
          <ScrollToTopRoute path="/SignUp" component={SignUp} />
          <ScrollToTopRoute component={NotFound} />
        </Switch>
      </Router>
    );
  }
}

export default App;
