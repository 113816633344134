import React from 'react';
import CustomNavbar from '../components/CustomNavbar';
import SequrityPrice from '../components/Features/SecurityPrice';
import FooterFour from '../components/Footer/FooterFour';

const Price = () => {
    return(
        <div className="body_wrapper">
            <CustomNavbar slogo="sticky_logo" mClass="menu_four" nClass="w_menu ml-auto mr-auto"/>
            <section className='faq'>
        <div className='container'>
            <div className='row'>
            <div className='col-xxl-6'>
              <h2>Our  <span className="color-green-900"> Pricing & Plans</span></h2>
               
                </div>   
                
            </div>
        </div>
           </section>
            <SequrityPrice/>
          <FooterFour />
        </div>
    )
}
export default Price;