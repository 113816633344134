import React from 'react';
import CustomNavbar from '../components/CustomNavbar';
import featurefiveicon1 from '../img/home2/trending.png';
import featurefiveicon2 from '../img/home2/application.png';
import featurefiveicon3 from '../img/home2/website.png';
import featurefiveicon4 from '../img/home2/mobile-device.png';
import Webdevelop1 from '../img/home2/Webdevelop1.png';
import Webdevelop2 from '../img/home2/Webdevelop2.png';
import ErpAction1 from '../components/ErpAction1';
import Reveal from 'react-reveal';
import TrackingActivity from '../components/TrackingActivity';
import FooterFour from '../components/Footer/FooterFour';
import { Helmet } from 'react-helmet';



const Websitedevelopment =()=>{
    
    return(
        <div className="body_wrapper">
        <Helmet>
        <title>Best Website Development Services Agency – The Profise</title>
    <meta name="title" content="Best Website Development Services Agency – The Profise" />
    <meta name="description" content="The Profise makes websites that enhance your brand image. Reach out to us for the top website development services and grow your business." />
        </Helmet>
        <CustomNavbar slogo="sticky_logo" mClass="menu_four" nClass="w_menu ml-auto mr-auto"/>

        <section className='webdevbanner'>
          <div className='container'>
            <div className='row'>
            <div className='col-md-8 col-lg-6 col-xxl-6 col-xl-6'>
              <h2>Creative website<span class="color-green1"> development solutions</span></h2>
            </div> 
            </div>
          </div>
        </section>
        <section className="freature-section-six pt-120 rpt-90 pb-90 rpb-60">
        <div className="container">
          <div className="row large-gap mb-30">
            <div className="col-xl-6 col-lg-6 col-sm-6">
              <div className="freature-six-left wow fadeInUp delay-0-2s">
                <div className="section-title mb-30">
                  <h2> <span className="color-green-900">Website Development  - </span> services we offer </h2>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-sm-6">
            <div className="freature-six-right pt-55 rpt-0 wow fadeInUp delay-0-4s">
                <p className='righttext-wh'> 
                We at The Profise are equipped with cutting-edge tools. We help you accomplish your IT goals in record time. Our website development services include: 
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-3 col-sm-6">
              <div className="feature-six-item mt-30 wow fadeInUp delay-0-2s">
                <div className="content">
                  <div className="icon">
                  <img src={featurefiveicon1} alt="" />
                   
                  </div>
                  <h5 className='payable-serv'>Matching the latest trends</h5>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="feature-six-item wow fadeInUp delay-0-4s">
                <div className="content">
                  <div className="icon">
                  <img src={featurefiveicon2} alt="" />
                  </div>
                  <h5 className='payable-serv'>Custom web application development</h5>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="feature-six-item mt-30 wow fadeInUp delay-0-6s">
                <div className="content">
                  <div className="icon">
                  <img src={featurefiveicon3} alt="" />
                  </div>
                  <h5 className='payable-serv'>Visually appealing website</h5>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="feature-six-item wow fadeInUp delay-0-8s">
                <div className="content">
                  <div className="icon">
                  <img src={featurefiveicon4} alt="" />
                  </div>
                  <h5 className='payable-serv'>Compatible with mobile devices and search engines</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
        </section>
        <section className="purple">
   <div className="container">
    <div className="row">
      <div className="col-xl-6 col-lg-6 col-sm-6">
        <div className="basic">
<h2><span class="acct">Website development -</span> what is it?</h2>
<div className="mke">
<p>Web development is the process of building and maintaining websites. Web development includes aspects like web publishing, web design, web programming, and database management. Website development is creating an application that works over the internet. </p>
<p>Web development is made using two words, the word web is referred to websites, web pages, or anything else which works over the internet, and development refers to building the application from scratch. </p>
</div>
        </div>
      </div>
      <div className="col-xl-6 col-lg-6 col-sm-6">
      <div className="fromback">
  <h3>Send Your Inquires</h3>
  <form
    action="fileUploadScript.php"
    method="post"
    encType="multipart/form-data"
  >
  <div className='row'>
    <div className='col-xxl-6 col-xl-6 col-md-12 col-12'>
    <label>Legal Company Name:*</label>
    <input type="text" name="name" className="form-control" required="" />
    </div>
    <div className='col-xxl-6 col-xl-6 col-md-12 col-12'>
    <label>Your Email:*</label>
    <input type="email" name="email" className="form-control" required="" />
    </div>
    <div className='col-xxl-6 col-xl-6 col-md-12 col-12'>
    <label>Your Number:*</label>
    <input type="tel" name="phone" className="form-control" required="" />
    </div>
    <div className='col-xxl-6 col-xl-6 col-md-12 col-12'>
    <label>Location</label>
    <input type="text" name="namentitye" className="form-control" required="" />
    </div>
    <div className='col-xxl-12 col-xl-12 col-md-12 col-12'>
    <label>Your Query</label>
    <textarea name="message" rows="4" class="form-control"> </textarea>
    </div>
       </div>
         <br></br>
    <input type="submit" className="form-rol" />
  </form>
</div>
      </div>
    </div>
   </div>

        </section>
        <section className='abouttwo'>
            <div className='container'>
                <div className='row' id='Profitable'>
                <div className='col-xxl-6 col-xl-6 col-md-6 col-12 girl text-center'>
                <img src={Webdevelop1} alt="" />
                 </div>
                <div className='col-xxl-6 col-xl-6 col-md-6 col-12'>
                 <h2> <span className="color-green-900">Benefits </span> of website development</h2>
                 <p>No organization can grow strong, without a physical website presence. To develop a website you need to hire a website developer like us. Website development offers several advantages, like:</p>
                 <br></br>
                 <ul class="Passion">
                    <li><span><i class="fa fa-angle-right" aria-hidden="true"></i></span>Reach more customers around the world.</li>
                    <li><span><i class="fa fa-angle-right" aria-hidden="true"></i></span>Boost your sales and revenue. </li>
                    <li><span><i class="fa fa-angle-right" aria-hidden="true"></i></span>User-friendly design interface.</li>
                 </ul>
                </div>

                </div>
                     </div>
        </section>
        <section className="get_started_area">
            <div className="container">
                <div className="row pb-20">
                    <div className="col-xl-5 col-lg-6 col-md-6 d-flex align-items-center">
                        <Reveal bottom cascade>
                            <div className="get_content">
                                <h2 className="l_height50 mb_20 wow fadeInLeft" data-wow-delay="0.3s"><span className='acct'>The website development
 </span> process </h2>                              
                            
                            </div>
                        </Reveal>
                    </div>
                    <div className="col-xl-7 col-lg-6 col-md-6 text-right">
                       <Reveal effect="fadeInRight" duration={2200}> 
                       <p className="righttext f_400 f_p mb_40 wow fadeInLeft" data-wow-delay="0.4s">We provide custom innovative suggestions and the best website solutions and let you know about the whole website development process. The website development process includes the following: </p>
                       </Reveal>
                    </div>
                </div>

                <div className="row">
                    <div className="col-xl-3 col-lg-3 col-md-6 d-flex align-items-center">
                        <Reveal bottom cascade>
                            <div className="get_content">
                            <Reveal effect="fadeInRight" duration={2200}> <img src={require('../img/home2/data-gathering1.png')} alt="" className='servimg'/></Reveal>
                            <h2 className="acc l_height50 mb_20 wow fadeInLeft" data-wow-delay="0.3s">Gathering information and planning accordingly. </h2>                                 
                            </div>
                        </Reveal>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-6 d-flex align-items-center">
                        <Reveal bottom cascade>
                            <div className="get_content">
                            <Reveal effect="fadeInRight" duration={2200}> <img src={require('../img/home2/coding.png')} alt="" className='servimg'/></Reveal>
                            <h2 className="acc l_height50 mb_20 wow fadeInLeft" data-wow-delay="0.3s">Web designing and development.</h2>                                   
                            </div>
                        </Reveal>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-6 d-flex align-items-center">
                        <Reveal bottom cascade>
                            <div className="get_content">
                            <Reveal effect="fadeInRight" duration={2200}> <img src={require('../img/home2/test.png')} alt="" className='servimg'/></Reveal>
                            <h2 className="acc l_height50 mb_20 wow fadeInLeft" data-wow-delay="0.3s">Testing the website before launch.</h2>                                          
                            </div>
                        </Reveal>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-6 d-flex align-items-center">
                        <Reveal bottom cascade>
                            <div className="get_content">
                            <Reveal effect="fadeInRight" duration={2200}> <img src={require('../img/home2/rocket.png')} alt="" className='servimg'/></Reveal>
                            <h2 className="acc l_height50 mb_20 wow fadeInLeft" data-wow-delay="0.3s">Launching the website and maintaining it. </h2>                                    
                            </div>
                        </Reveal>
                    </div>
                </div>

            

            </div>
        </section>
        <section className="why-learn-area pb-120 rpb-100">
        <div className="container">
          <div className="row align-items-center large-gap wow fadeInLeft delay-0-2s">
            <div className="col-xl-6 col-lg-6 col-md-6">
              <div className="why-learn-content rmb-35">
                <div className="section-title mb-30">
                   <h2><span className="color-green-900">Why </span>are our website development services different? </h2>
                  <p className="Profitable">We at The Profise focus on providing quality and cost-effective web solutions to convert your website’s visitors into clients. </p>
                </div>
                <p>Our web development services consist of the end solutions- architecture consulting and design, application development, application maintenance, reporting, and resolution. We believe, every business should have a website, without a website business is incomplete.</p>
                <p>To develop a website for your business we combine rich functionality with an appealing design and robust management tools to provide our customers with complete web solutions for all kinds of websites.</p>
               
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 text-center">
              <div className="why-learn-image wow fadeInUp" data-wow-delay="0.4s">
               <img src={Webdevelop2} className="bluegirl" alt="" />
            
              </div>
            </div>
          </div>
        </div>
        </section>
        <ErpAction1/>
        <FooterFour />
        </div>
    )
}
export default Websitedevelopment;