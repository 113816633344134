import React from 'react';
import CustomNavbar from '../components/CustomNavbar';
import growth from '../img/home2/growth.png';
import Reconciliation1 from '../img/home2/Reconciliation1.png';
import Reconciliation22 from '../img/home2/Reconciliation22.png';
import ErpAction1 from '../components/ErpAction1';
import Reveal from 'react-reveal';
import FooterFour from '../components/Footer/FooterFour';
import { Helmet } from 'react-helmet';



const Reconciliation =()=>{
    
    return(
        <div className="body_wrapper">
        <Helmet>
        <title>Account Payroll Reconciliation Services Company</title>
    <meta name="title" content="Account Payroll Reconciliation Services Company" />
    <meta name="description" content="The Profise has Reconciliation that is comparing two sets of records to ensure they match and agree to resolve any discrepancies between the two documents." />
        </Helmet>
        <CustomNavbar slogo="sticky_logo" mClass="menu_four" nClass="w_menu ml-auto mr-auto"/>

        <section className='Reconciliation'>
          <div className='container'>
            <div className='row'>
            <div className='col-xxl-7 col-xl-7 col-lg-7 col-md-7'>
              <h2>Effective<span class="color-green1"> Reconciliation Of Your Accounts</span></h2>
            </div> 
            </div>
          </div>
        </section>
        <section className='innersection1'>
<div className='container'>
  <div className='row'>
    <div className='col-xxl-6 col-xl-6 col-md-6 col-12'>
    <h2> <span className="color-green-900">Reconciliation</span>- Services We Offer  </h2>
    <p className='match'>We at The Profise help you match bank transactions with financial accounts, so the financial statements and balance sheets are accurate and informative.</p>
    <p><strong>Our key services for Reconciliation are: </strong></p>
    <ul class="Passion">
    <li><span><i class="fa fa-angle-right" aria-hidden="true"></i></span>Reconciliation of sales with statutory returns</li>
    <li><span><i class="fa fa-angle-right" aria-hidden="true"></i></span>Reconciliation of customers' accounts </li>
    <li><span><i class="fa fa-angle-right" aria-hidden="true"></i></span>Reconciliation of vendors' accounts </li>
    <li><span><i class="fa fa-angle-right" aria-hidden="true"></i></span>Reconciliation of year-end balances with financial statements
</li>
    </ul>
    </div>
    <div className='col-xxl-6 col-xl-6 col-md-6 col-12'>
    <div className="frombackinner">
  <h3>Send Your Inquires</h3>
  <form
    action="fileUploadScript.php"
    method="post"
    encType="multipart/form-data"
  >
  <div className='row'>
    <div className='col-xxl-6 col-xl-6 col-md-12 col-12'>
    <label>Legal Company Name:*</label>
    <input type="text" name="name" className="form-control" required="" />
    </div>
    <div className='col-xxl-6 col-xl-6 col-md-12 col-12'>
    <label>Your Email:*</label>
    <input type="email" name="email" className="form-control" required="" />
    </div>
    <div className='col-xxl-6 col-xl-6 col-md-12 col-12'>
    <label>Your Number:*</label>
    <input type="tel" name="phone" className="form-control" required="" />
    </div>
    <div className='col-xxl-6 col-xl-6 col-md-12 col-12'>
    <label>Location</label>
    <input type="text" name="namentitye" className="form-control" required="" />
    </div>
    <div className='col-xxl-12 col-xl-12 col-md-12 col-12'>
    <label>Your Query</label>
    <textarea name="message" rows="4" class="form-control"> </textarea>
    </div>
       </div>
         <br></br>
    <input type="submit" className="form-rol" />
  </form>
</div>
    </div>
  </div>
</div>
        </section>
        <section className="purple">
   <div className="container">
    <div className="row">
      <div className="col-xl-6 col-lg-6 col-sm-6">
        <div className="basic">
    <h2><span class="acct"> Reconciliation</span> - What Is It?</h2>
<div className="mke">
<p>Reconciliation is a process in accounting after the cash and bank balances have been determined. It could mean two things, to match up the balance you have in your books with the actual balances in a bank account, or it may also be used as a verb meaning to bring two accounts into an agreement with each other.</p>
<p>Account reconciliation is advantageous because it explains the discrepancies in your account balances or financial data. Some differences are allowed, but unexplained or inexplicable inconsistencies can indicate accounting fraud. </p>
</div>
        </div>
      </div>
      <div className="col-xl-6 col-lg-6 col-sm-6">
        <div className="basic2">
        <img src={growth}  alt="" />
        </div>
      </div>
    </div>
   </div>

        </section>
        <section className='abouttwo'>
            <div className='container'>
                <div className='row' id='Profitable'>
                <div className='col-xxl-6 col-xl-6 col-md-6 col-12 girl text-center'>
                <img src={Reconciliation1}  alt="" />
                 </div>
                <div className='col-xxl-6 col-xl-6 col-md-6 col-12'>
                 <h2> <span className="color-green-900">Benefits </span>Of Reconciliation</h2>
                 <p className='Profitable'>Reconciliation must be done regularly to identify fraudulent activities in your account. It helps to confirm that all the payments are accurate and that no charges are above or below the intended amount.  </p>
                 <br></br>
                 <p>Everyone has to reconcile accounts, whether their business is big or small. The main aim of reconciliation is to balance the recorded balance in the ledger that matches your bank statements. The benefits of reconciliation are:</p>
                 <br></br>
            
                </div>

                </div>
                <div className='row' id='tripple'>
                <div className="col-lg-4">
                        <div className="media tracking_item">
                            <img src={require ("../img/home-tracking/next.png")} alt=""/>
                            <div className="media-body">
                                <h3 className="h_head">It helps eliminate fraudulent activities.</h3>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="media tracking_item">
                            <img src={require ("../img/home-tracking/next.png")} alt=""/>
                            <div className="media-body">
                                <h3 className="h_head">It helps in controlling theft.</h3>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="media tracking_item">
                            <img src={require ("../img/home-tracking/next.png")} alt=""/>
                            <div className="media-body">
                               <h3 className="h_head">It helps in monitoring bookkeeping.
</h3>
                            </div>
                        </div>
                </div>
                </div>
                     </div>
        </section>
        <section className="get_started_area">
            <div className="container">
                <div className="row pb-20">
                    <div className="col-xl-5 col-lg-6 col-md-6 d-flex align-items-center">
                        <Reveal bottom cascade>
                            <div className="get_content">
                             <h2 className="l_height50 mb_20 wow fadeInLeft" data-wow-delay="0.3s"><span className='acct'>The </span>  Reconciliation Process </h2>                              
                               </div>
                        </Reveal>
                    </div>
                    <div className="col-xl-7 col-lg-6 col-md-6 text-right">
                       <Reveal effect="fadeInRight" duration={2200}> 
                       <p className="righttext f_400 f_p mb_40 wow fadeInLeft" data-wow-delay="0.4s">Reconciliation brings two sets of financial or other information previously recorded in separate records into agreement. <strong>The process of reconciliation includes the following: 
</strong></p>
                       </Reveal>
                    </div>
                </div>

                <div className="row">
                    <div className="col-xl-3 col-lg-3 col-md-6 d-flex align-items-center">
                        <Reveal bottom cascade>
                            <div className="get_content">
                            <Reveal effect="fadeInRight" duration={2200}> <img src={require('../img/home2/bank-statement.png')} alt="" className='servimg'/></Reveal>
                            <h2 className="acc l_height50 mb_20 wow fadeInLeft" data-wow-delay="0.3s">Get a correct transaction record of the business account.</h2>                                 
                            </div>
                        </Reveal>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-6 d-flex align-items-center">
                        <Reveal bottom cascade>
                            <div className="get_content">
                            <Reveal effect="fadeInRight" duration={2200}> <img src={require('../img/home2/calculate.png')} alt="" className='servimg'/></Reveal>
                            <h2 className="acc l_height50 mb_20 wow fadeInLeft" data-wow-delay="0.3s">Calculate all entries opposite to the bank statements.</h2>                                   
                            </div>
                        </Reveal>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-6 d-flex align-items-center">
                        <Reveal bottom cascade>
                            <div className="get_content">
                            <Reveal effect="fadeInRight" duration={2200}> <img src={require('../img/home2/income-statement.png')} alt="" className='servimg'/></Reveal>
                            <h2 className="acc l_height50 mb_20 wow fadeInLeft" data-wow-delay="0.3s">Generate financial statements and notify about errors.</h2>                                          
                            </div>
                        </Reveal>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-6 d-flex align-items-center">
                        <Reveal bottom cascade>
                            <div className="get_content">
                            <Reveal effect="fadeInRight" duration={2200}> <img src={require('../img/home2/verify.png')} alt="" className='servimg'/></Reveal>
                            <h2 className="acc l_height50 mb_20 wow fadeInLeft" data-wow-delay="0.3s">Correct all mistakes to avoid errors in future reconciliation</h2>                                    
                            </div>
                        </Reveal>
                    </div>
                </div>

            

            </div>
        </section>
        <section className="why-learn-area pb-120 rpb-100">
        <div className="container">
          <div className="row align-items-center large-gap wow fadeInLeft delay-0-2s">
            <div className="col-xl-6 col-lg-6 col-md-6">
              <div className="why-learn-content rmb-35">
                <div className="section-title mb-30">
                  <h2><span className="color-green-900">Why </span> Are Our Reconciliation Services Different?</h2>
                  <p className="Profitable">To provide our customers with the best services, we follow a reconciliation routine per every client's need, whether weekly, monthly or even daily. </p>
                </div>
                <p>
                We help you match your bank account transactions with your financial accounts to ensure that your financial balances and statements are accurate. Reconciliation of your bank account is essential and helps to check if your accounts are reconciled. 
                </p>
                <p>
                We help our clients reconcile their accounts and find any errors and inconsistencies in their accounts. We work hard to provide them with a unique solution to remove these inconsistencies from their accounts. 

                </p>
               
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 text-center">
              <div className="why-learn-image wow fadeInUp" data-wow-delay="0.4s">
               <img src={Reconciliation22} className="bluegirl"  alt="" />
            
              </div>
            </div>
          </div>
        </div>
        </section>
        <ErpAction1/>
        <FooterFour />
                  </div>
    )
}
export default Reconciliation;