import React from 'react';
import CustomNavbar from '../components/CustomNavbar';
import accounting from '../img/home2/accounting.png';
import document1 from '../img/home2/document1.png';
import agreement1 from '../img/home2/agreement1.png';
import account23 from '../img/home2/account23.png';
import verifie from '../img/home2/verifie.png';
import FooterFour from '../components/Footer/FooterFour';


const Onboarding = () => {
    return(
        <div className="body_wrapper">
            <CustomNavbar slogo="sticky_logo" mClass="menu_four" nClass="w_menu ml-auto mr-auto"/>
            <section className='onboarding'>
                <div className='container'>
                    <div className='row'>
                    <div className="col-xl-6 col-lg-6 col-md-6">
                     <h2 className='Business'>Here’s <span className="color-green1">how we take you onboard </span></h2>
                    </div>
            <div className="col-lg-6">
              </div>
            </div>
                </div>
                  </section>
                  <section className='allserivces'>
                  <div className="container">
  <div className="row">
    <div className="col-md-6 col-xxl-3 col-xl-3 col-12">
      <div className="serviceBox">
        <div className="service-content">
          <div className="service-icon">
          <img src={accounting} alt="" />
          <i class="fa fa-folder-o" aria-hidden="true"></i>
          </div>
          <h3 className="title">Accounts Payable</h3>
          <p className="description">
          Accounts payable is a term used to describe the money owed to suppliers or vendors for the goods purchased on credits.
          </p>
        </div>
        <a className="read-more" href="/Accountspayable">
          Read More
        </a>
      </div>
    </div>
    <div className="col-md-6 col-xxl-3 col-xl-3 col-12">
      <div className="serviceBox green">
        <div className="service-content">
          <div className="service-icon">
          <img src={document1} alt="" />
          </div>
          <h3 className="title">Accounts Receivable</h3>
          <p className="description">
          Accounts receivable is the total amount of money the company has received from selling its products or services but not yet collected in cash.
          </p>
        </div>
        <a className="read-more" href="/AccountsReceivable">
          Read More
        </a>
      </div>
    </div>
    <div className="col-md-6 col-xxl-3 col-xl-3 col-12">
      <div className="serviceBox">
        <div className="service-content">
          <div className="service-icon">
          <img src={agreement1} alt="" />
          </div>
          <h3 className="title">Reconciliation</h3>
          <p className="description">
          Reconciliation is a process to match up the balance you have in your books with the actual balances in a bank account or to bring two accounts into an agreement.
          </p>
        </div>
        <a className="read-more" href="/Reconciliation">
          Read More
        </a>
      </div>
    </div>
    <div className="col-md-6 col-xxl-3 col-xl-3 col-12">
      <div className="serviceBox green">
        <div className="service-content">
          <div className="service-icon">
          <img src={agreement1} alt="" />
          </div>
          <h3 className="title">Budgeting & Forecasting</h3>
          <p className="description">
          Budgeting outlines planned expenses and revenue over a while of a business, while forecasting is a well-thought-out projection of a business's outcomes in the future.
          </p>
        </div>
        <a className="read-more" href="/BudgetingForecasting">
          Read More
        </a>
      </div>
    </div>
  </div>
  <div className="row" id='serivceorw'>
    <div className="col-md-6 col-xxl-3 col-xl-3 col-12">
      <div className="serviceBox">
        <div className="service-content">
          <div className="service-icon">
          <img src={accounting} alt="" />
          <i class="fa fa-folder-o" aria-hidden="true"></i>
          </div>
          <h3 className="title">MIS/Financial Statements Preparation-</h3>
          <p className="description">
          MIS/financial statements preparation is the process of creating financial statements from a company's accounting records. 
          </p>
        </div>
        <a className="read-more" href="/MISFinancialStatementsPreparation">
          Read More
        </a>
      </div>
    </div>
    <div className="col-md-6 col-xxl-3 col-xl-3 col-12">
      <div className="serviceBox green">
        <div className="service-content">
          <div className="service-icon">
          <img src={verifie} alt="" />
          </div>
          <h3 className="title">Payroll</h3>
          <p className="description">
          Accounts receivable is the total amount of money the company has received from selling its products or services but not yet collected in cash.
          </p>
        </div>
        <a className="read-more" href="/Payroll">
          Read More
        </a>
      </div>
    </div>
    <div className="col-md-6 col-xxl-3 col-xl-3 col-12">
      <div className="serviceBox">
        <div className="service-content">
          <div className="service-icon">
          <img src={agreement1} alt="" />
          </div>
          <h3 className="title">Tax Compliance & Filings</h3>
          <p className="description">
          Tax compliance is referred to the taxpayers’ decision to comply with tax regulations and laws by paying the tax accurately and on time.
          </p>
        </div>
        <a className="read-more" href="/TaxCompliance&Filings">
          Read More
        </a>
      </div>
    </div>
    <div className="col-md-6 col-xxl-3 col-xl-3 col-12">
      <div className="serviceBox green">
        <div className="service-content">
          <div className="service-icon">
          <img src={account23} alt="" />
          </div>
          <h3 className="title">Bookkeeping-</h3>
          <p className="description">
          Bookkeeping is an essential part of business accounting; it is the process of recording all your company’s financial transactions in an organized account on daily basis. 
          </p>
        </div>
        <a className="read-more" href="/Bookkeeping-">
          Read More
        </a>
      </div>
    </div>
  </div>
</div>

                  </section>
        
      <FooterFour />
        </div>
    )
}
export default Onboarding;