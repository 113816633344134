import React from 'react';
import CustomNavbar from '../components/CustomNavbar';
import touch from '../img/home2/touch.jpg';
import ErpAction1 from '../components/ErpAction1';
import aboutfirst from '../img/home2/aboutfirst.png';
import profit from '../img/home2/profit.jpg';
import profit1 from '../img/home2/profit1.jpg';
import profit2 from '../img/home2/profit2.jpg';
import bigarrow from '../img/home2/bigarrow.png';
import womanholding from '../img/home2/womanholding.png';
import { Link } from 'react-router-dom';
import FooterFour from '../components/Footer/FooterFour';
import { Helmet } from 'react-helmet';

const Whychooseus =()=>{
    return(
        <div className="body_wrapper">
        <Helmet>
        <title>Why Choose Us – The Profise</title>
    <meta name="title" content="Why Choose Us – The Profise" />
    <meta name="description" content="The Profise always believes in being transparent with our customers. Our team holds honesty, fairness, and reliability to give you the best experience." />
        </Helmet>
            <CustomNavbar slogo="sticky_logo" mClass="menu_four" nClass="w_menu ml-auto mr-auto"/>
        <section className='Myhome'>
        <div className='container'>
            <div className='row'>
            <div className='col-md-6'>
              <h2>Build <span class="color-green1">more scalable business</span></h2>
               </div>   
               
            </div>
        </div>
        </section>
        <section className='abouttwo'>
            <div className='container'>
                <div className='row'>
                <div className='col-xxl-6 col-xl-6 col-md-6 col-12'>
                <img src={aboutfirst} className="aboutfirst"  alt="" />
                
                </div>
                <div className='col-xxl-6 col-xl-6 col-md-6 col-12'>
                 <h2><span className="color-green-900">Why do </span> businesses choose us? </h2>
                 <p className='Profitable'>We ensure to lower your business taxes and save your money as we take over the things that are time-consuming.</p>
                   <br></br>
                  <ul class="Passion">
                  <li><span><i class="fa fa-angle-right" aria-hidden="true"></i></span>Need new staff? We will hire for you.</li>
                  <li><span><i class="fa fa-angle-right" aria-hidden="true"></i></span>Need to handle accounts? We are here.</li>
                  <li><span><i class="fa fa-angle-right" aria-hidden="true"></i></span>Need a new website? We can build one.</li>
                  <li><span><i class="fa fa-angle-right" aria-hidden="true"></i></span>We do your task in minutes and save you time.</li></ul>
                 <Link to="/about" className="z-btn z-btn-border">ABOUT US</Link>
                </div>
                </div>
            </div>
        </section>      
        <section className=" header second">
    <div className="container py-4">
           <div className="row">
      <div className="col-md-8">
          {/* Tabs content */}
          <div className="tab-content" id="v-pills-tabContent">
            <div
              className="tab-pane active"
              id="v-pills-home"
              role="tabpanel"
              aria-labelledby="v-pills-home-tab">
              <div className='row'>
                <div className='col-md-7 col-12'>
                <h2 className='Dedicated'><span class="acct">We push</span> you above the bar</h2>
                  <p className="consectetur mb-2">
                    Start your business, and we will help you accomplish your goals. You don’t need to overpay for your taxes. You will get high-level guidance and support from us to grow and scale your business.
                  </p>
                  <p className="consectetur mb-2">Our experts with in-depth knowledge make your work accurate, time effective and efficient. We have great experience in the finance, digital marketing and accounting field. We are highly qualified to take care of your company’s needs. </p>
                </div>
                <div className='col-md-5 col-12'>
                <img src={touch}  alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          {/* Tabs nav */}
     
          <div
            className="nav flex-column nav-pills nav-pills-custom"
            id="v-pills-tab"
            role="tablist"
            aria-orientation="vertical"
          >
               <h3 className='Ethics'>Our Ethics: </h3>
            <a
              className="nav-link mb-3 p-3  active"
              id="v-pills-home-tab"
              data-toggle="pill"
              href="#v-pills-home"
              role="tab"
              aria-controls="v-pills-home"
              aria-selected="true"
            >
              <i class="fa fa-pie-chart" aria-hidden="true"></i>
              <span className="Resolution">
              Honesty
              </span>
            </a>
            <div
              className="nav-link mb-3 p-3 "
              id="v-pills-profile-tab"
              data-toggle="pill"
              href="#v-pills-profile"
              role="tab"
              aria-controls="v-pills-profile"
              aria-selected="false"
            >
             <i class="fa fa-comments" aria-hidden="true"></i>
              <span className="Resolution">
              Constant Support
              </span>
            </div>
            <div
              className="nav-link mb-3 p-3 "
              id="v-pills-messages-tab"
              data-toggle="pill"
              href="#v-pills-messages"
              role="tab"
              aria-controls="v-pills-messages"
              aria-selected="false"
            >
              <i class="fa fa-shopping-bag" aria-hidden="true"></i>
              <span className="Resolution">
              Transparency
              </span>
            </div>
            <div
              className="nav-link mb-3 p-3 "
              id="v-pills-settings-tab"
              data-toggle="pill"
              href="#v-pills-settings"
              role="tab"
              aria-controls="v-pills-settings"
              aria-selected="false"
            >
             <i class="fa fa-money" aria-hidden="true"></i>
              <span className="Resolution">
              Fairness
              </span>
            </div>
          
          </div>
        </div>
       
      </div>
    </div>
        </section>
        <section  className='aboutfour'>
    <div className='container'>
        <div className='row'>
        <div className="col-xl-6 col-lg-6 col-sm-6">
              <div className="freature-six-left wow fadeInUp delay-0-2s">
                <div className="section-title mb-30">
                  <h2> <span className="color-green-900">  Focus on </span> your goals and leave the rest to us!</h2>
                     </div>
                            </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-sm-6">
            <div className="freature-six-right pt-55 rpt-0 wow fadeInUp delay-0-4s">
                <p className='righttext-wh'>
                Ease down as we handle your business operations. The Profise is a team of professionals who help businesses with bookkeeping, taxes, data entry, digital marketing and many more.
                </p>
              </div>
            </div>
        </div>
        <div className='row' id='profit'>
            <div className='col-xxl-4 col-md-4 col-xl-4 col-12'>
            <img src={profit} className="profit"  alt="" />
            <div className='Planning'>
        <h3> Reconcile your transactions efficiently</h3>
        <p>We ensure to reconcile your transactions without any mistakes the first time only.</p>
        </div>
               </div>
               <div className='col-xxl-4 col-md-4 col-xl-4 col-12'>
            <img src={profit1} className="profit"  alt="" />
            <div className='Planning'>
        <h3> Peer-review your business financials. </h3>
        <p>Two of our experts review your financial statements every time to ensure they are perfect. </p>
        </div>
               </div>
               <div className='col-xxl-4 col-md-4 col-xl-4 col-12'>
            <img src={profit2} className="profit"  alt="" />
            <div className='Planning'>
        <h3> Reach You Timely</h3>
        <p>We always respond you in one or fewer business days because our clients are our priority.</p>
        </div>
               </div>
            
        </div>
    </div>
        </section>
        <section className='aboutfive'>
  <div className='container'>
    <div className='row'>
    <div className='col-xxl-6 col-xl-6 col-md-6 col-12' id='Team'>
    <h2><span class="acct"> Your success </span>is our priority</h2>
    <p>We have experts that help in making your business operations more firm. Whether it’s accounting or marketing we do the work error-free. We offer you with:</p>
 <br></br>
<ul className="Passion1">
  <li><span><i className="fa fa-angle-right" aria-hidden="true" /></span>
  Maximum accuracy  </li>
  <li><span><i className="fa fa-angle-right" aria-hidden="true" /></span>
  Certified experts
  </li>
  <li> <span> <i className="fa fa-angle-right" aria-hidden="true" /></span>
  Assured security
  </li>
  <li>
    <span>
      <i className="fa fa-angle-right" aria-hidden="true" />
    </span>
    Positive environment
  </li>
 </ul>

 <Link to="/about" className="z-btn z-btn-border">ABOUT US</Link>
    </div>
   
    <div className='col-xxl-6 col-xl-6 col-md-6 col-12' >
    <img src={bigarrow} className="bigarrow"  alt="" />
    </div>
    </div>
  </div>
        </section>
        <section className='aboutsix'>
   <div className='container'>
    <div className='row'>
        <div className='col-xxl-6 col-xl-6 col-md-6 col-12'>
        <img src={womanholding} className="womanholding"  alt="" />
        </div>
        <div className='col-xxl-6 col-xl-6 col-md-6 col-12'>
        <div className='Thinking'>
           <h2><span className="color-green-900">What makes</span> us this distinct?  </h2>
            <p class="Profitable">There are several business service providers, but we care a little extra for you. Some features that make customers choose us:</p>

            <ul class="Passion"><li><span><i class="fa fa-angle-right" aria-hidden="true"></i></span>We handle your most chaotic tasks and let you focus on your strengths. </li>
            <li><span><i class="fa fa-angle-right" aria-hidden="true"></i></span>We have highly experienced professionals.</li>
        <li><span><i class="fa fa-angle-right" aria-hidden="true"></i></span>We help you reduce your taxes and save more.</li>
            </ul>
            </div>
        </div>
    </div>
   </div>
        </section>
        <ErpAction1/>
        <FooterFour />
        </div>
    )
}
export default Whychooseus;