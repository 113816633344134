import React from 'react';
import CustomNavbar from '../components/CustomNavbar';
import Healthcare1 from '../img/home2/Healthcare1.png';
import Healthcare2 from '../img/home2/Healthcare2.png';
import {Link} from 'react-router-dom';
import featurefiveicon1 from '../img/home2/accounting1.png';
import featurefiveicon2 from '../img/home2/network1.png';
import featurefiveicon3 from '../img/home2/checklist1.png';
import featurefiveicon4 from '../img/home2/social-media1.png';
import ErpAction1 from '../components/ErpAction1';
import FooterFour from '../components/Footer/FooterFour';
import { Helmet } from 'react-helmet';


const Healthcare = () => {
    return(
        <div className="body_wrapper">
        <Helmet>
        <title>Accounting Services For Healthcare Industry – The Profise</title>
    <meta name="title" content="Accounting Services For Healthcare Industry – The Profise" />
    <meta name="description" content="By partnering with The Profise, healthcare organizations can focus on providing quality patient care and leave financial management to the experts." />
        </Helmet>
            <CustomNavbar slogo="sticky_logo" mClass="menu_four" nClass="w_menu ml-auto mr-auto"/>
            <section className='healthbanner'>
        <div className='container'>
            <div className='row'>
            <div className='col-md-8 col-xxl-6 col-xl-6'>
              <h2>Transforming <span class="color-green1"> the healthcare industry</span></h2>
                </div>   
                
            </div>
        </div>
            </section>
            <section className='abouttwo'>
            <div className='container'>
                <div className='row'>
                <div className='col-xxl-6 col-xl-6 col-md-6 col-12'>
                <img src={Healthcare1} className="aboutfirst" />
                
                </div>
                <div className='col-xxl-6 col-xl-6 col-md-6 col-12'>
                 <h2> <span className="color-green-900">One step </span> forward for a better workflow </h2>
                 <p className='Profitable'>We give the best and most optimised solutions to healthcare firms for all types of projects.</p>
                 <br></br>
                 <p>We have a vast network, innovative technology, and experts who encourage a stronger, more coordinated, and more efficient healthcare system.</p>
                 <Link to="/About-us" className="z-btn z-btn-border">ABOUT US</Link>
                </div>
                </div>
            </div>
            </section> 
            <section className="purple">
   <div className="container">
    <div className="row">
      <div className="col-lg-6 col-xxl-6 col-md-12 col-xxl-6 col-12">
        <div className="basic">
<h2><span className="color-green-900">Advanced </span> technology & Innovative solutions </h2>
<div className="mke">
<p>The Profise has technology and services that will help you in enhancing patient engagement, improving results and revenue and improving operational efficiency.</p>
<p>We help healthcare firms in tracking the status of payments. This improves revenue cycle management and reduces errors in billing and coding.</p>
</div>
        </div>
      </div>
      <div className="col-lg-6 col-xxl-6 col-xxl-6 col-12">
        <div className="basic2">
        <img src={Healthcare2} />
        </div>
      </div>
    </div>
   </div>

            </section>  
            <section className="freature-section-six pt-120 rpt-90 pb-90 rpb-60">
        <div className="container">
          <div className="row large-gap mb-30">
            <div className="col-lg-6">
              <div className="freature-six-left wow fadeInUp delay-0-2s">
                <div className="section-title mb-30">
                  <h2> <span className="color-green-900">Manage </span> your resources   </h2>       
                </div>
              </div>
            </div>
            <div className="col-lg-5">
              <div className="freature-six-right pt-55 rpt-0 wow fadeInUp delay-0-4s">
                <p>
                Our service providers will help you to manage jobs like scheduling appointments, billing, and managing patient records.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-3 col-sm-6">
              <div className="feature-six-item mt-30 wow fadeInUp delay-0-2s">
                <div className="content">
                  <div className="icon">
                  <img src={featurefiveicon1} />
                   
                  </div>
                  <h5 className='payable'>Accounting</h5>
                                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="feature-six-item wow fadeInUp delay-0-4s">
                <div className="content">
                  <div className="icon">
                  <img src={featurefiveicon2} />
                  </div>
                  <h5 className='payable'>Staffing</h5>
                                 </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="feature-six-item mt-30 wow fadeInUp delay-0-6s">
                <div className="content">
                  <div className="icon">
                  <img src={featurefiveicon3} />
                  </div>
                  <h5 className='payable'>Data Entry</h5>
                                 </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="feature-six-item wow fadeInUp delay-0-8s">
                <div className="content">
                  <div className="icon">
                  <img src={featurefiveicon4} />
                  </div>
                  <h5 className='payable'>Digital Marketing </h5>
                                 </div>
              </div>
            </div>
          </div>
        </div>
            </section>
           <ErpAction1/>
           <FooterFour />
           </div>
    )
}
export default Healthcare;