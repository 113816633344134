import React from 'react';
import CustomNavbar from '../components/CustomNavbar';
import gentleman from '../img/home2/gentleman.png';
import pie from '../img/home2/pie.png';
import ErpAction1 from '../components/ErpAction1';
import FooterFour from '../components/Footer/FooterFour';
import { Helmet } from 'react-helmet';



const Aboutus =()=>{
    return(
        <div className="body_wrapper">
<Helmet>
<title>About Us – The Profise</title>
    <meta name="title" content="About Us – The Profise" />
    <meta name="description" content="We manage everything from accounting to marketing your business to improve productivity. Join hands with the Profise and rise above all." />
</Helmet>
            <CustomNavbar slogo="sticky_logo" mClass="menu_four" nClass="w_menu ml-auto mr-auto"/>
                  
  <section className="home2">
    <div className="container">
        <div className="row">
            <div className="col-lg-6">
                <h2>We handle <span className="color-green1">your business with care </span></h2>
            </div>
            <div className="col-lg-6">
              
            </div>
        </div>
    </div>
  </section>
  <section className="features-section-three rel z-1 pt-110 rpt-85 pb-100 rpb-70">
        <div className="container">
          <div className="section-title mb-55">
             <h2>Stuck with numbers? <span>contact us!</span></h2>
            <p className="Profitable">Our business professionals will help you focus on running your business while taking care of all the essential operations. </p>
          </div>

        </div>
  </section>
  <section className='minwidth'>
          <div className='container'>
            <div className='row'>
            <div className='col-xxl-4 col-xl-6 col-md-6 col-12 vission'>
            <div className="get_content1">
                <h2 className='mb_40'><span className='acct'>Take </span>a step ahead  </h2>
                <p className="f_400 f_p wow fadeInLeft" data-wow-delay="0.4s">Let us take care of your tax preparation and bookkeeping needs, and you focus on running your business. </p>
                <h4 className='aim'>We aim to :-</h4>
                  <ul className='sli-ul1 bot-padding'>
                      <li>Simplify your complex business operations.</li>
                      <li>Solve your tax-related issues with ease</li>
                      <li>Decrease your costs and help you increase your savings.</li>
                      <li>Relieve your stress by handling your finances. </li>
                  </ul>
               {/* <a href=".#" className="chat_btn1 btn_hover mt-3">Call Now</a> */} 
               </div>
            </div>

            <div className='col-xxl-4 col-xl-6 col-md-6 col-12 mission'>          

               <div className="get_contentbl">
                <h2 className='mb_40'><span className='acct1'>Who </span>are we? </h2>
                <p className="f_400 f_p mb_40 wow fadeInLeft" data-wow-delay="0.4s">We are a 360-degree service providers worldwide, with proven results from our clients. Whether you want help regarding website development, invoicing clients, paying taxes, and bills, compliance filling, hiring new staff or running your payroll. We are always here to help you. We also offer personalized services to provide you with the best.
</p>   
                <img src={require('../img/home2/calgirl.png')} alt="" className='servimggirl'/> 

            </div>
            </div>

            
                
            </div>
          </div>
  </section>
  <section className='aboutthree'>
          <div className='container'>
            <div className='row'>
            <div className='col-xxl-6 col-xl-6 col-md-6 col-12'>
            <div className="img-section">
            <img src={pie}className="man" />
						</div>
            </div>

            <div className='col-xxl-6 col-xl-6 col-md-6 col-12'>          
              <div className="get_content">
                <h2 className="l_height50 mb_20 wow fadeInLeft" data-wow-delay="0.3s"><span className='acct'>What </span>do we do? </h2>
                <p className=" f_400 f_p wow fadeInLeft" data-wow-delay="0.4s">We offer our customers quality services at competitive prices. We are dedicated to keeping your business’ finances accurate and clean, so you can focus on running your business effectively.</p>  
                <a href="/Contact" className="chat_btn1 btn_hover mt-3">Get Started</a>
                                       
            </div>
                </div>
                
            </div>
          </div>

  </section>
  <section className="why-learn-area pb-120 rpb-100">
        <div className="container">
          <div className="row align-items-center large-gap wow fadeInLeft delay-0-2s">
          <div className="col-xxl-6 col-xl-6 col-md-6 col-12 text-center">
              <div className="why-learn-image wow fadeInRight delay-0-2s">
              <img src={gentleman} className="gent" />
                
              </div>
            </div>
            <div className="col-xxl-6 col-xl-6 col-md-6 col-12">
              <div className="why-learn-content rmb-35">
                <div className="section-title">
                  <h2>Why team Profise is <span>the best ?</span></h2>
                </div>
                <p className='other'>
                We are equipped with the essential infrastructure to provide efficient results on time. We will help you make optimized business decisions with our knowledge and expertise. Our specialities:
                </p>
                <ul className="Passion">
  <li>
    <span>
      <i className="fa fa-angle-right" aria-hidden="true" />
    </span>
    Unlimited support
  </li>
  <li>
    <span>
      <i className="fa fa-angle-right" aria-hidden="true" />
    </span>
    Provide better business solutions
  </li>
  <li>
    <span>
      <i className="fa fa-angle-right" aria-hidden="true" />
    </span>
    Increase your business’ productivity
  </li>
  <li>
    <span>
      <i className="fa fa-angle-right" aria-hidden="true" />
    </span>
    Guaranteed accuracy
  </li>

</ul>
              </div>
            </div>
           
          </div>
        </div>
  </section>
  <ErpAction1/>
  <FooterFour />
  </div>
    )
}
export default Aboutus;