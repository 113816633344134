import React from 'react';
import CustomNavbar from '../components/CustomNavbar';
import growth from '../img/home2/growth.png';
import pay11oll from '../img/home2/pay11oll.png';
import payroll2 from '../img/home2/payroll2.png';
import ErpAction1 from '../components/ErpAction1';
import Reveal from 'react-reveal';
import FooterFour from '../components/Footer/FooterFour';
import { Helmet } from 'react-helmet';



const Payroll =()=>{
    return(
        <div className="body_wrapper">
        <Helmet>
        <title>Payroll Outsourcing Management Services – The Profise</title>
    <meta name="title" content="Payroll Outsourcing Management Services – The Profise" />
    <meta name="description" content="The Profise has Payroll services that regulate paying employees for their work and maintaining accurate compensation records." />
        </Helmet>
        <CustomNavbar slogo="sticky_logo" mClass="menu_four" nClass="w_menu ml-auto mr-auto"/>

      <section className='Payroll'>
          <div className='container'>
            <div className='row'>
            <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6'>
                 <h2> Simplify<span class="color-green1"> Your Payroll Process</span></h2>
            </div> 
            </div>
          </div>
      </section>
      <section className='innersection1'>
<div className='container'>
  <div className='row'>
    <div className='col-xxl-6 col-xl-6 col-md-6 col-12'>
    <h2> <span className="color-green-900">Payroll</span>- Services We Offer   </h2>
    <p className='match'>We at The Profise integrate the payroll system with your financial accounts for properly and precisely recording your payroll expenses in your books. </p>
    <p><strong>Our key services for Payroll are:</strong></p>
    <ul class="Passion">
    <li><span><i class="fa fa-angle-right" aria-hidden="true"></i></span>Filing all your forms and taxes on time.</li>
    <li><span><i class="fa fa-angle-right" aria-hidden="true"></i></span>Monthly and quarterly filing of your returns and payrolls. </li>
    <li><span><i class="fa fa-angle-right" aria-hidden="true"></i></span>Integrating all your payroll expenses into account books. </li>
    <li><span><i class="fa fa-angle-right" aria-hidden="true"></i></span>Payroll expenses reporting for proper control. 
</li>
    </ul>
    </div>
    <div className='col-xxl-6 col-xl-6 col-md-6 col-12'>
    <div className="frombackinner">
  <h3>Send Your Inquires</h3>
  <form
    action="fileUploadScript.php"
    method="post"
    encType="multipart/form-data"
  >
  <div className='row'>
    <div className='col-xxl-6 col-xl-6 col-md-12 col-12'>
    <label>Legal Company Name:*</label>
    <input type="text" name="name" className="form-control" required="" />
    </div>
    <div className='col-xxl-6 col-xl-6 col-md-12 col-12'>
    <label>Your Email:*</label>
    <input type="email" name="email" className="form-control" required="" />
    </div>
    <div className='col-xxl-6 col-xl-6 col-md-12 col-12'>
    <label>Your Number:*</label>
    <input type="tel" name="phone" className="form-control" required="" />
    </div>
    <div className='col-xxl-6 col-xl-6 col-md-12 col-12'>
    <label>Location</label>
    <input type="text" name="namentitye" className="form-control" required="" />
    </div>
    <div className='col-xxl-12 col-xl-12 col-md-12 col-12'>
    <label>Your Query</label>
    <textarea name="message" rows="4" class="form-control"> </textarea>
    </div>
       </div>
         <br></br>
    <input type="submit" className="form-rol" />
  </form>
</div>
    </div>
  </div>
</div>
      </section>
      <section className="purple">
   <div className="container">
    <div className="row">
      <div className="col-xl-6 col-lg-6 col-sm-6">
        <div className="basic">
<h2><span class="acct">Payroll</span> - What Is It? </h2>
<div className="mke">
<p>Payroll is one of the key activities for every business. It is the process of paying all employees and making tax payments. Payroll is a significant expense for many companies and is always deductible. Payroll varies from one pay period to another for different reasons such as sick pay, overtime, etc. </p>
<p>Several companies that have expanded their business to global markets have switched to reliable payroll processing service providers to handle their payroll process. If your payroll department is also struggling with these variables, you need an experienced service provider like us.</p>
</div>
        </div>
      </div>
      <div className="col-xl-6 col-lg-6 col-sm-6">
        <div className="basic2">
        <img src={growth} alt="" />
        </div>
      </div>
    </div>
   </div>

      </section>
      <section className='abouttwo'>
            <div className='container'>
                <div className='row' id='Profitable'>
                <div className='col-xxl-6 col-xl-6 col-md-6 col-12 girl text-center'>
                <img src={pay11oll} alt="" />
                 </div>
                <div className='col-xxl-6 col-xl-6 col-md-6 col-12'>
                <h2> <span className="color-green-900"> Benefits </span>Of Payroll</h2>
                 <p className='Profitable'>The payroll includes many processes, from the employee's recording in the payroll register, salary payment through bank transfer, and generating payslips to tracking deductions. </p>
                 <br></br>
                 <p>The primary benefit of payroll processing is that it can produce various reports, simplifying the accounting process and helping companies comply with legal and tax filing requirements. The benefits of payroll are: </p>
                 <br></br>
            
                </div>

                </div>
                <div className='row' id='tripple'>
                <div className="col-lg-4">
                        <div className="media tracking_item">
                            <img src={require ("../img/home-tracking/next.png")} alt=""/>
                            <div className="media-body">
                                <h3 className="h_head">Gives access to several reports..</h3>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="media tracking_item">
                            <img src={require ("../img/home-tracking/next.png")} alt=""/>
                            <div className="media-body">
                                <h3 className="h_head">Simplifies accounting and tax compliance.</h3>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="media tracking_item">
                            <img src={require ("../img/home-tracking/next.png")} alt=""/>
                            <div className="media-body">
                               <h3 className="h_head">Records the vacation & personal time taken by employees.</h3>
                            </div>
                        </div>
                </div>
                </div>
                     </div>
      </section>
      <section className="get_started_area">
            <div className="container">
                <div className="row pb-20">
                    <div className="col-xl-5 col-lg-6 col-md-6 d-flex align-items-center">
                        <Reveal bottom cascade>
                            <div className="get_content">
                             <h2 className="l_height50 mb_20 wow fadeInLeft" data-wow-delay="0.3s"><span className='acct'>The Payroll</span> Process </h2>                              
                               </div>
                        </Reveal>
                    </div>
                    <div className="col-xl-7 col-lg-6 col-md-6 text-right">
                       <Reveal effect="fadeInRight" duration={2200}> 
                       <p className="righttext f_400 f_p mb_40 wow fadeInLeft" data-wow-delay="0.4s">Processing payroll can be quite a tedious task. So we roll up our sleeves and do this for you. This involves many critical tasks emphasizing accuracy and compliance with all legal requirements.  
                       <strong> The Payroll process includes the following: </strong></p>
                       </Reveal>
                    </div>
                </div>

                <div className="row">
                    <div className="col-xl-3 col-lg-3 col-md-6 d-flex align-items-center">
                        <Reveal bottom cascade>
                            <div className="get_content">
                            <Reveal effect="fadeInRight" duration={2200}> <img src={require('../img/home2/data-gathering.png')} alt="" className='servimg'/></Reveal>
                            <h2 className="acc l_height50 mb_20 wow fadeInLeft" data-wow-delay="0.3s">Gather and validate the employees' input.</h2>                                 
                            </div>
                        </Reveal>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-6 d-flex align-items-center">
                        <Reveal bottom cascade>
                            <div className="get_content">
                            <Reveal effect="fadeInRight" duration={2200}> <img src={require('../img/home2/payroll.png')} alt="" className='servimg'/></Reveal>
                            <h2 className="acc l_height50 mb_20 wow fadeInLeft" data-wow-delay="0.3s">Calculate payrolls & disburse employee salaries.</h2>                                   
                            </div>
                        </Reveal>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-6 d-flex align-items-center">
                        <Reveal bottom cascade>
                            <div className="get_content">
                            <Reveal effect="fadeInRight" duration={2200}> <img src={require('../img/home2/maintenance.png')} alt="" className='servimg'/></Reveal>
                            <h2 className="acc l_height50 mb_20 wow fadeInLeft" data-wow-delay="0.3s">Maintain accurate records of all payroll transactions.</h2>                                          
                            </div>
                        </Reveal>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-6 d-flex align-items-center">
                        <Reveal bottom cascade>
                            <div className="get_content">
                            <Reveal effect="fadeInRight" duration={2200}> <img src={require('../img/home2/tax-sheet.png')} alt="" className='servimg'/></Reveal>
                            <h2 className="acc l_height50 mb_20 wow fadeInLeft" data-wow-delay="0.3s">Distribute tax computation sheets and payslips. </h2>                                    
                            </div>
                        </Reveal>
                    </div>
                </div>

            

            </div>
      </section>
      <section className="why-learn-area pb-120 rpb-100">
        <div className="container">
          <div className="row align-items-center large-gap wow fadeInLeft delay-0-2s">
            <div className="col-xl-6 col-lg-6 col-md-6">
              <div className="why-learn-content rmb-35">
                <div className="section-title mb-30">
                   <h2><span className="color-green-900"> Why </span> Are Our Payroll Services Different? </h2>
                  <p className="Profitable">We have the desired expertise and skills to provide you with the best quality payroll services within a quick turnaround time. </p>
                </div>
                <p>
                We at The Profise always consider that employees are the most valuable resource of an organization. So, our payroll management team always makes sure to timely file the payrolls, including the proper deductions, direct deposits, etc. 
                </p>
                <p>
                We help you issue payments efficiently and timely. Our services go through multiple quality checks to ensure 100% precision. 

                </p>
               
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 text-center">
              <div className="why-learn-image wow fadeInUp" data-wow-delay="0.4s">
               <img src={payroll2} className="bluegirl"  alt="" />
            
              </div>
            </div>
          </div>
        </div>
      </section>
      <ErpAction1/>
       <FooterFour />
                  </div>
    )
}
export default Payroll;