import React from 'react';
import CustomNavbar from '../components/CustomNavbar';
import Featuresitems from '../components/Features/Featuresitems';
import { Link } from 'react-router-dom';
import FooterFour from '../components/Footer/FooterFour';
import { Helmet } from 'react-helmet';


const Onboarding = () => {
    return(
        <div className="body_wrapper">
        <Helmet>
        <title>On Boarding Business Operation – The Profise</title>
    <meta name="title" content="On Boarding Business Operation – The Profise" />
    <meta name="description" content="The Profise can help businesses onboard their operations by setting up effective financial systems, tracking financial transactions, and several other things." />
        </Helmet>
            <CustomNavbar slogo="sticky_logo" mClass="menu_four" nClass="w_menu ml-auto mr-auto"/>
            <section className='onboarding'>
                <div className='container'>
                    <div className='row'>
                    <div className="col-xl-6 col-lg-6 col-md-6">
                     <h2 className='Business'>Here’s <span className="color-green1">how we take you onboard </span></h2>
                    </div>
            <div className="col-lg-6">
              </div>
            </div>
                </div>
                  </section>
         
            <section className="process_area bg_color sec_pad">
                <div className="container">
                <div className='row'>
                <div className='col-xxl-12 col-xl-12 col-md-12 col-12 text-center'>
                    <div className='onbordingprocess'>
                        <h2><span class="color-green-900">Just 3 </span>steps for the better business operations </h2>
                    </div>
                    </div>
                </div>
                    <div className="features_info">
                        <img className="dot_img" src={require ('../img/home2/divider2.png')} alt=""/>
                        <Featuresitems rowClass="row flex-row-reverse" aClass="pr_70 pl_70" fimage="process_1.png" iImg="icon01.png" ftitle="Fill out the form" descriptions="If you wish to work with us, just fill out the form given with all the correct details including your company name, type, your business’s starting date etc and our team will reach out to you after receiving the information. "/>
                        <Featuresitems rowClass="row" aClass="pr_70 pl_70.features_info .dot_img" fimage="process_2.png" iImg="icon02.png" ftitle="Analysis of your needs & goals" descriptions="The next step is a phone call, our experts will contact you and note everything you are looking for. Then they can analyse your needs and present a suitable plan for you. "/>
                        <Featuresitems rowClass="row flex-row-reverse" aClass="pr_70 pl_70" fimage="process_3.png" iImg="icon3.png" ftitle="The concluding process" 
                        descriptions="After you have picked your choices and the team has suggested you the plans, you can do the payment through credit cards or other payment methods you receive in the payment link."/>
                       
                    </div>
                </div>
            </section>
            <section className='obsection3'>
            <div className='container'>
                <div className='row'>
                    <div className='col-xxl-6 col-xl-6 col-md-6 col-12 col-sm-12 '>
                        <h2>Your <span class="act">data is safe here! </span></h2>
                        <p className='relies'>Fill out this form so we can know your expectations and deliver our best services.  We make sure that all the information you provide is kept confidential and will not be shared with anyone else without your permission.</p>
                <p className='relies'>The Profise works with integrity and dedication.  </p>
                    <br></br>
                    <Link to="/About-us" className="z-btn z-btn-border onboard">ABOUT US</Link>
                    </div>
                    <div className='col-xxl-6 col-xl-6 col-md-6 col-12'>
                    <div className="fromback">
  <h3>Send Your Inquires</h3>
  <form
    action="fileUploadScript.php"
    method="post"
    encType="multipart/form-data"
  >
  <div className='row'>
    <div className='col-xxl-6 col-xl-6 col-md-12 col-12'>
    <label>Legal Company Name:*</label>
    <input type="text" name="name" className="form-control" required="" />
    </div>
    <div className='col-xxl-6 col-xl-6 col-md-12 col-12'>
    <label>Your Email:*</label>
    <input type="email" name="email" className="form-control" required="" />
    </div>
    <div className='col-xxl-6 col-xl-6 col-md-12 col-12'>
    <label>Your Number:*</label>
    <input type="tel" name="phone" className="form-control" required="" />
    </div>
    <div className='col-xxl-6 col-xl-6 col-md-12 col-12'>
    <label>Location</label>
    <input type="text" name="namentitye" className="form-control" required="" />
    </div>
    <div className='col-xxl-12 col-xl-12 col-md-12 col-12'>
    <label>Your Query</label>
    <textarea name="message" rows="4" class="form-control"> </textarea>
    </div>
       </div>
         <br></br>
    <input type="submit" className="form-rol" />
  </form>
</div>
                    </div>
                </div>
            </div>
        </section>
         <FooterFour />
        </div>
    )
}
export default Onboarding;