import React from 'react';
import CustomNavbar from '../components/CustomNavbar';
import ErpAction1 from '../components/ErpAction1';
import FooterFour from '../components/Footer/FooterFour';



const Privacypolicy =()=>{
    

    return(
        <div className="body_wrapper">
        <CustomNavbar slogo="sticky_logo" mClass="menu_four" nClass="w_menu ml-auto mr-auto"/>

        <section className='aboutbanner1'>
          <div className='container'>
            <div className='row'>
            <div className='col-xxl-6 col-xl-6 col-lg-5 col-md-5'>
              <h2>Privacy<span class="color-green1"> policy</span></h2>
            </div> 
            </div>
          </div>
        </section>
        <section className="why-learn-area pb-120 rpb-100">
        <div className="container">
          <div className="row align-items-center large-gap wow fadeInLeft delay-0-2s">

            <div className="col-xl-12 col-lg-12 col-md-12">
              <div className="why-learn-content rmb-35">
                <div className="section-title mb-30">
                  <p className="Profitable">At Profise, we are committed to protecting the privacy of our users. This privacy policy explains how we collect, use, and share personal information about you when you use our website and its services. </p>
                </div>
                <h4 className='priv'><span>Information </span>We Collect </h4>
                <p>We may collect the following types of personal information about you when you use our website </p>
                <ul>
                  <li>Contact information: This includes your name, email address, and phone number.</li>
                  <li>Financial information:  It contains data such as your bank account or credit card numbers</li>
                  <li>Other personal information:  Your address or social security number, as needed for the provision of our accounting services</li>
                </ul>  

                <h4 className='priv'><span>Use </span>of Information</h4>
                <p>We use the information we collect about you to provide accounting services and to communicate with you about our services. We may also use your information to improve our website and to personalize your experience when you use it.</p>


                <h4 className='priv'><span>Sharing</span> of Information</h4>
                <p>Your personal information might be shared with third parties for the following purposes:</p>
                <ul>
                  <li>To service providers and other third parties that assist us in providing our accounting services</li>
                  <li>To government agencies or other legal authorities as required by law</li>
                  <p>We take steps to protect the privacy of your personal information when it is shared with third parties. We require that these parties comply with our privacy policies and use the information only for the purposes for which it is shared.</p>
                </ul>


                <h4 className='priv'><span>Data </span> Security</h4>
                <p>We take appropriate technical and organizational measures to protect your personal information from unauthorized access, use, or disclosure. These measures include the use of secure servers and encryption of sensitive data.</p>


                <h4 className='priv'><span>User </span>Rights</h4>
                <p>You have the following rights in relation to your personal information:</p>
                <ul>
                  <li>The right to access, modify, or delete your personal information</li>
                  <li>The right to object or oppose the processing of your personal information</li>
                  <li>The right to deny the processing of your personal information</li>
                  <p>You may exercise these rights by contacting us using the contact information provided below.</p>
                </ul>

                <h4 className='priv'><span>Changes </span>to This Privacy Policy</h4>
                <p>This privacy policy is updated regularly. If we make any material changes to the policy, we will post the updated policy on this page and indicate the effective date of the revised policy. We encourage you to review this privacy policy regularly to stay informed about how we are protecting the personal information we collect.</p>

                <h4 className='priv'><span>Contact </span> Us</h4>
                <p>If you have any questions or concerns about our privacy practices, please contact us using the following information:</p>
                <p><strong>Email:</strong> profise@gmail.com</p>
                <p><strong>Phone number:</strong> +948 256 347 968</p>


              </div>
            </div>

          </div>
        </div>
        </section>
        <ErpAction1/>
        <FooterFour />
        </div>
    )
}
export default Privacypolicy;