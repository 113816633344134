import React, {Component} from 'react';

class SequrityPrice extends Component {
    constructor(){
        super();
        this.state= {
            list: [
                {
                    id: 1,
                    adTitle: "Email Handling / Customer Response Services",
                    icon: 'icon_close',
                    icon2: 'icon_check_alt2',
                 
                },
                {
                    id: 2,
                    adTitle: "Billing (Direct)",
                    icon: 'icon_check_alt2',
                    icon2: 'icon_check_alt2',
                 
                },
                {
                    id: 3,
                    adTitle: "Billing (Factoring)",
                    icon: 'icon_check_alt2',
                    icon2: 'icon_check_alt2',
             
                },
                {
                    id: 4,
                    adTitle: "Accounts Receivable",
                    icon: 'icon_check_alt2',
                    icon2: 'icon_check_alt2',
                 
                },
                {
                    id: 5,
                    adTitle: "Chargeback Resolution",
                    icon: 'icon_check_alt2',
                    icon2: 'icon_check_alt2',
               
                },
                {
                    id: 6,
                    adTitle: "AR Claims",
                    icon: 'icon_check_alt2',
                    icon2: 'icon_check_alt2',
               
                },
                {
                    id: 7,
                    adTitle: "Accounts Payable",
                    icon: 'icon_check_alt2',
                    icon2: 'icon_check_alt2',
             
                
                },
                {
                    id: 8,
                    adTitle: "Daily Billing Report",
                    icon: 'icon_close',
                    icon2: 'icon_check_alt2',
          
                },
                {
                    id: 9,
                    adTitle: "Monthly Profit & Loss Analysis",
                    icon: 'icon_close',
                    icon2: 'icon_check_alt2',
       
                },
                {
                    id: 10,
                    adTitle: "Quarterly Profit & Loss Analysis",
                    icon: 'icon_close',
                    icon2: 'icon_check_alt2',
         
                },
                {
                    id: 12,
                    adTitle: "Forecasting",
                    icon: 'icon_close',
                    icon2: 'icon_check_alt2',
              
                },
                {
                    id: 13,
                    adTitle: "Budgeting",
                    icon: 'icon_close',
                    icon2: 'icon_check_alt2',
           
                },
                {
                    id: 14,
                    adTitle: "Expense Tracking",
                    icon: 'icon_close',
                    icon2: 'icon_check_alt2',
     
                },
                {
                    id: 15,
                    adTitle: "Financial Statements Preparation",
                    icon: 'icon_close',
                    icon2: 'icon_check_alt2',
                  
                },
                {
                    id: 16,
                    adTitle: "Payroll Processing",
                    icon: 'icon_close',
                    icon2: 'icon_check_alt2',
     
                },
                {
                    id: 17,
                    adTitle: "1099 Preparation & Filling",
                    icon: 'icon_close',
                    icon2: 'icon_check_alt2',
                  
                },
                {
                    id: 18,
                    adTitle: "Tax Preparation & Filling",
                    icon: 'icon_close',
                    icon2: 'icon_check_alt2',
              
                },
            ]
        }
    }
    render(){
        return(
            <section className="pricing_area_four sec_pad">
            <div className="container">
                <div className="hosting_title security_title text-center">
                    <h2 className="wow fadeInUp" data-wow-delay="0.2s"><span>Choose</span> what suits you best! </h2>
                </div>
                <div className="price_info_two price_info_three">
                    <div className="price_head">
                        <div className="p_head time">
                            <h4>Account Package Detail</h4>
                        </div>
                        <div className="p_head">
                            <h5>Billing Package</h5>
                            </div>
                        <div className="p_head">
                            <h5>Accounting & Tax Package</h5>
                            </div>
                     
                    </div>
                    <div className="price_body">
                        {
                            this.state.list.map( list => {
                                return(
                                    <div className="pr_list" key={list.id}>
                                        <div className="price_item">
                                        <h5 className="pr_title" data-toggle="tooltip" data-placement="top" title="" data-original-title="Save time by using keyboard shortcuts all across SaasLand">{list.adTitle}</h5>
                                        </div>
                                        <div className="price_item" data-title="Free">
                                            <h5 className="check"><i className={list.icon}></i></h5>
                                        </div>
                                        <div className="price_item" data-title="Enterprise">
                                            <h5 className="check"><i className={list.icon2}></i></h5>
                                        </div>
                                       
                                    </div>
                                )
                            })
                        }
                        <div className="pr_list">
                            <div className="price_item" id='price'>
                                             </div>
                            <div className="price_item" id='premium2'>
                                <a href="/#" className="price_btn btn_hover">$125</a>
                            </div>
                             <div className="price_item" id='premium'>
                                <a href="/#" className="price_btn btn_hover">$250</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        )
    }
}
export default SequrityPrice;